import React from 'react';
import classnames from 'classnames';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewRoom } from 'src/REST/capture';
import texts, { analytics } from 'src/texts/texts';

import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Loading from 'src/components/Loading/Loading';
import Modal from 'src/components/Modal/Modal';
import SelectionGrid from 'src/components/SelectionGrid/SelectionGrid';
import Text from 'src/components/Text/Text';

import * as log from 'src/utils/logger';

import type { AxiosError } from 'axios';
import { eventNames } from 'src/utils/events';

const rooms = [texts.bathroom, texts.bedroom, texts.kitchen, texts.basement, texts.living, texts.laundry, texts.garage, texts.dining, texts.other];

const RoomSelectPageLayoutCapture = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [roomType, setRoomType] = useState('');

  const onSelect = (selectedRoom: string) => () => {
    log.event({
      event: eventNames.SELECT_ROOM,
      data: {
        page: 'Room Select Page',
        env: process.env.REACT_APP_ENV,
        selectedRoom,
      },
    });
    setRoomType(selectedRoom);
  };

  const handleClearDb = async () => {
    await dexieDb.pictures.clear();
  };

  const handleSelectRoom = async (roomName: string, roomDesc: string) => {
    const roomNameTrim = roomName?.trim();
    if (roomType === '') return;
    setLoad(true);
    try {
      const { data } = await addNewRoom(params.claimId || '', roomType, roomNameTrim || roomType, roomDesc);
      if (data) {
        log.info({
          event: eventNames.ROOM_SELECTED_SUCCESS,
          data: {
            type: roomType,
            template: 'roomTypeSelection',
            header: 'Room Type Selection :house:',
          },
        });
        await handleClearDb();
        navigate(`/${params.captureType}/${params.claimId}/${data.roomId}/tips`);
      }
    } catch (e) {
      const error = e as AxiosError;
      log.error({
        event: eventNames.ROOM_SELECTED_FAIL,
        ignoreSlack: false,
        data: { header: 'Room type selection error', error: e },
      });
      if (error?.response) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          setError(error?.response?.data as string);
        }
      }
      setRoomType('');
      setError('Unable to Continue. Please contact Administrator');
    } finally {
      setLoad(false);
    }
  };

  const handleClose = () => setLoad(false);

  const elementsWithData = rooms.map((room, index) => {
    const roomType = room.split(' ')?.[0] || 'other';
    return {
      text: room,
      name: roomType,
      className: classnames('basis-[calc(33%_-_5px)] pt-[calc(33%_-_5px)]', {
        'mt-2': index > 2,
        'mr-1': index % 3 === 0,
        'mx-1': index % 3 === 1,
        'ml-1': index % 3 === 2,
      }),
    };
  });

  const handleNext = () => {
    handleSelectRoom(roomType, 'NA');
  };

  return (
    <>
      <DamageHeader hideRestart />
      <ContentView>
        <div className="w-full flex-1 flex flex-col items-center h-full pb-24">
          <div className="w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop pb-8">
            <div className="pb-3">
              <Text variant="title">Room Type</Text>
            </div>
            <Text variant="subtitle" align="left">
              Please select the room type that you will photograph today.{' '}
            </Text>
          </div>

          <SelectionGrid checked={roomType} onClick={onSelect} elements={elementsWithData} />

          <Footer extraClassname="border-t bg-white pt-4">
            <BaseButton lightBg disabled={!roomType} name={analytics.selectRoom} onClick={handleNext} label={texts.next} dataTestId="handle-select-room" />
          </Footer>
          {error && <Modal handleClose={() => setError(null)} title="Caution" text={error} buttonText="Got it" />}
          <Loading open={load} handleClose={handleClose} />
        </div>
      </ContentView>
    </>
  );
};

export default RoomSelectPageLayoutCapture;
