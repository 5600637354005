import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDb, IPicture } from 'src/utils/indexedDb';
import Text from 'src/components/Text/Text';
import texts from 'src/texts/texts';
import ButtonImage from 'src/components/ButtonImage/ButtonImage';
import BaseButton from 'src/components/BaseButton/BaseButton';
import RetakeCamera from 'src/components/icons/common/RetakeCamera';
import Footer from 'src/components/Footer/Footer';
import { useFeatureStore } from 'src/stores/featureStore';

type Props = {
  handleNext: () => void;
  damageId: string;
};

const DamageReview = ({ handleNext }: Props) => {
  const [selected, setSelected] = useState<string | null>(null);
  const { roomId, captureType, claimId } = useParams();
  const navigate = useNavigate();
  const entityConfig = useEntityConfigStore((state) => state.config);
  const damageLandingCopy = useEntityConfigStore((state) => state.copy.damageLanding);
  const tipsVisible = useFeatureStore((state) => state.tipsVisible);

  const handleNextAction = () => {
    if (selected === 'Yes') {
      handleNext();
    }
    if (selected === 'No') {
      if (tipsVisible) navigate(`/${captureType}/${claimId}/${roomId}/tips`);
      else navigate(`/${captureType}/${claimId}/${roomId}/capture-room`);
    }
  };

  const pictures =
    useLiveQuery(
      async () =>
        dexieDb.pictures
          .where('roomId')
          .equals(roomId || '')
          .and((p) => p.imgType !== 'overviewImage')
          .toArray(),
      [roomId]
    ) || [];

  const groups = pictures.reduce((prev: any, curr: IPicture) => {
    if (curr.damageId && curr.imgType) {
      prev[curr.damageId] = {
        ...(prev[curr.damageId] || {}),
        [curr.imgType]: curr.content,
        [curr.imgType + '-id']: curr.id,
      };
    }
    return prev;
  }, {});

  const showIndex = Object.keys(groups).length > 1;

  const handleCapture = async (data: string, id: number) => {
    await dexieDb.pictures.update(id, { content: data });
  };

  return (
    <>
      <Text variant="title">{damageLandingCopy.review.title}</Text>
      <Text variant="subtitle" extraClassname="mt-4">
        {texts.confirmDamageSubtitle}
      </Text>
      <div className="flex gap-5 py-4 mt-10">
        <ButtonImage
          selected={selected === 'Yes'}
          onClick={() => setSelected('Yes')}
          flexDirection="col"
          textAlign="center"
          outlined
          icon="/icons/check@2x.png"
          label={damageLandingCopy.review.yesBtn}
        />
        <ButtonImage
          selected={selected === 'No'}
          onClick={() => setSelected('No')}
          flexDirection="col"
          textAlign="center"
          outlined
          icon="/icons/x@2x.png"
          testId="no-more-damage-btn"
          label={damageLandingCopy.review.noBtn}
        />
      </div>
      {Object.keys(groups).map((e: any, idx: number) => {
        return (
          <div key={idx}>
            {groups[e]['damage-zoomed-in'] ? (
              <>
                <div className="flex items-center py-4">
                  <h5 className="text-[#001D47] text-left w-full font-medium">
                    {damageLandingCopy.closeUp.heading} {showIndex && damageLandingCopy.review.indexPrefix}
                    {showIndex && idx + 1}
                  </h5>
                  <BaseButton
                    contained
                    type="capture"
                    onCapture={(d) => handleCapture(d, groups[e]['damage-zoomed-in-id'])}
                    weight="medium"
                    className="text-icons-color border"
                    icon={<RetakeCamera className="fill-icons-color" />}
                    label="retake photo"
                    capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                  />
                </div>
                <img alt="Damage-Zoomed-In" src={groups[e]['damage-zoomed-in']} className="rounded-lg w-full" />
              </>
            ) : null}

            {groups[e]['damage-zoomed-out'] ? (
              <>
                <div className="flex items-center py-4">
                  <h5 className="text-[#001D47] text-left w-full font-medium">
                    {damageLandingCopy.context.heading} {showIndex && damageLandingCopy.review.indexPrefix}
                    {showIndex && idx + 1}
                  </h5>
                  <BaseButton
                    contained
                    type="capture"
                    onCapture={(d) => handleCapture(d, groups[e]['damage-zoomed-out-id'])}
                    weight="medium"
                    className="text-icons-color border"
                    icon={<RetakeCamera className="fill-icons-color" />}
                    label="retake photo"
                    capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                  />
                </div>
                <img alt="Damage-Zoomed-Out" src={groups[e]['damage-zoomed-out']} className="rounded-lg w-full" />
              </>
            ) : null}
          </div>
        );
      })}
      <div className="mt-32" />
      <Footer extraClassname="bg-white pt-4 border-t">
        <div className="bg-white w-full">
          <BaseButton dataTestId="damage-next-page-btn" onClick={handleNextAction} label="Next" disabled={selected === null} />
        </div>
      </Footer>
    </>
  );
};

export default DamageReview;
