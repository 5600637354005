import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';

declare global {
  interface Window {
    opera?: any;
    MSStream?: any;
  }
}

export const compressImage = (file: File): Promise<File> => {
  return new Promise((resolve) => {
    if (file.size < 500000) {
      resolve(file);
    } else {
      new Compressor(file, {
        maxHeight: 1024,
        maxWidth: 1024,
        quality: 0.9,
        success: (compressedResult) => {
          resolve(
            new File([compressedResult], file.name, {
              type: 'image/jpeg',
            })
          );
        },
        error: (err) => {
          console.log(err);
          resolve(file);
        },
      });
    }
  });
};

export const base64ToFile = (base64Image: string, contentType = 'image/jpeg') => {
  const sliceSize = 512;
  const byteCharacters = window.atob(base64Image);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new File([new Blob(byteArrays, { type: contentType })], 'uploaded-image.jpg', { type: contentType });
};

export const convertBase64ImageToFileAndCompress = async (base64Image: string, contentType = 'image/jpeg') => {
  return await compressImage(base64ToFile(base64Image, contentType));
};

export const convertInputImageToBase64 = (file: File, webWorker?: boolean): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = async (fileReadEvent) => {
      return resolve(fileReadEvent.target?.result as string);
    };
    if (webWorker) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      imageCompression(file, options)
        .then((newFile) => {
          fileReader.readAsDataURL(newFile);
        })
        .catch((err) => {
          console.log(err);
          fileReader.readAsDataURL(file);
        });
    } else {
      compressImage(file)
        .then((newFile) => {
          fileReader.readAsDataURL(newFile);
        })
        .catch((err) => {
          console.log(err);
          fileReader.readAsDataURL(file);
        });
    }
  });
};

export const getBase64StringFromDataURL = (dataURL: string) => dataURL.replace('data:', '').replace(/^.+,/, '');

export const IS_TEST_ENV = process.env.JEST_WORKER_ID !== undefined || process.env.REACT_APP_PLAYWRIGHT === '1';

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhone = (phone: string): boolean => {
  // This regex checks for a 10-digit phone number
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phone);
};

export const isValidEmailOrPhone = (input: string): boolean => {
  return isValidEmail(input) || isValidPhone(input);
};
