import React from 'react';
import { StringLiteral } from 'typescript';

import OutlineSelectionButton from '../OutlineSelectionButton/OutlineSelectionButton';

export interface GridElement {
  text: string;
  name: string;
  className?: string;
}

export interface ISelectionGrid {
  className?: StringLiteral;
  onClick: (element: string) => React.MouseEventHandler<HTMLButtonElement>;
  elements?: Array<GridElement>;
  checked?: string[] | string;
  multiple?: boolean;
}

const getText = (text: string) => {
  if (text === 'living' || text === 'dining') {
    return `${text} room`;
  } else if (text === 'other') {
    return 'other/open concept';
  } else {
    return text;
  }
};

const SelectionGrid = ({ onClick, elements, checked, multiple }: ISelectionGrid) => {
  return (
    <div className="w-full flex flex-row flex-wrap justify-center">
      {elements?.map((element, index) => (
        <OutlineSelectionButton
          data-testid={`grid-outline-selection-button-${element.text}`}
          key={index}
          onClick={onClick(element.text)}
          text={getText(element.text)}
          className={element.className}
          name={element.name}
          checked={multiple ? checked?.includes(element.text) : checked === element.text}
        />
      ))}
    </div>
  );
};

export default SelectionGrid;
