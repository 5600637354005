import React, { useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import { useParams } from 'react-router-dom';
import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';

type Timeout = ReturnType<typeof setTimeout>;

const useCheckZoomSupport = (webcamRef: React.RefObject<Webcam>) => {
  const { roomId } = useParams();
  const [zoom, setZoom] = useState(1);
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');

  useEffect(() => {
    let timeoutId: Timeout | null = null;
    const checkZoomSupport = async () => {
      if (webcamRef?.current?.video?.readyState === 4) {
        try {
          // Get the video track from the webcam stream
          const videoTrack =
            // @ts-ignore
            webcamRef?.current?.video?.srcObject?.getVideoTracks?.()?.[0];

          // Get the capabilities of the video track
          const capabilities = videoTrack.getCapabilities();

          // Check for zoom support
          if ('zoom' in capabilities) {
            const { min, max } = capabilities.zoom;
            const isWideAngleSupported = min <= 0.5 && max >= 0.5;

            if (isWideAngleSupported) {
              setZoom(0.5);
            }

            log.event({ event: eventNames.WIDE_ANGLE_CAMERA, data: { roomId, supported: isWideAngleSupported } });
          } else {
            log.event({ event: eventNames.ZOOM_NOT_SUPPORTED, data: { roomId } });
          }

          setStatus('success');
        } catch (error) {
          setStatus('error');
          // @ts-ignore
          log.event({ event: eventNames.ERROR_ACCESSING_CAMERA_CAPABILITIES, data: { roomId, error: error?.message } });
        }
      } else {
        timeoutId = setTimeout(checkZoomSupport, 500);
      }
    };

    if (status === 'loading') {
      checkZoomSupport();
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [roomId, status, webcamRef]);

  return { zoom, status };
};

export default useCheckZoomSupport;
