import MobileStepper from '@mui/material/MobileStepper';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import React, { Fragment, useEffect, useState } from 'react';
import { useClaimInfoStore } from 'src/stores/claimInfo';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import InstructionTitle from 'src/components/InstructionTitle/InstructionTitle';

import { info } from '../../utils/logger';
import mixpanel from 'mixpanel-browser';
import { eventNames } from 'src/utils/events';

interface QuestionProps {
  label: string;
  subtitle?: string;
  options?: { title: string; subtitle?: string }[];
  rating?: boolean;
}

interface QuestionViewProps extends QuestionProps {
  handleClick: (data: string) => void;
}

export default function SurveyPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const companyLogo = useClaimInfoStore((state) => state.logo);
  const logoVerified = useClaimInfoStore((state) => state.logoVerified);
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    if (activeStep >= questions.length) {
      data.forEach((e, idx) => {
        info({
          event: eventNames.SURVEY_FEEDBACK + (idx + 1),
          data: { question: questions[idx].label, answer: e },
        });
      });
      mixpanel.reset();
    }
  }, [activeStep, data]);

  const handleClick = (result: string) => {
    setData(data.concat(result));
    setActiveStep(activeStep + 1);
  };

  return (
    <ContentView>
      <DamageHeader hideRestart hideBack />
      {activeStep >= questions.length && (
        <div className="flex flex-col items-center">
          <img alt="Hosta Logo" data-testid="hosta-logo" className="w-24 mx-auto min-w-[100px] mb-[40px]" src={logoVerified && companyLogo ? companyLogo : '/images/damage/Hosta Logo.png'} />
          <InstructionTitle className="text-center" text="Thank you for your valuable feedback." subtitleText="You may close this page now" />
        </div>
      )}
      {activeStep < questions.length && (
        <QuestionView
          handleClick={handleClick}
          label={questions[activeStep].label}
          options={questions[activeStep].options || []}
          rating={questions[activeStep].rating || false}
          subtitle={questions[activeStep].subtitle || undefined}
        />
      )}
      {activeStep < questions.length && (
        <Footer>
          <>
            <BaseButton onClick={() => handleClick('NA')} label="Skip Question" />
            <MobileStepper variant="progress" steps={questions.length} position="static" activeStep={activeStep} className="w-full" nextButton={<div />} backButton={<div />} />
            <small className="text-center">Close this window to skip feedback.</small>
          </>
        </Footer>
      )}
    </ContentView>
  );
}

const QuestionView = (props: QuestionViewProps) => {
  const { label, subtitle, options, rating, handleClick } = props;
  const [value, setValue] = useState<string | number | null>(null);

  useEffect(() => {
    setValue(null);
  }, [label]);

  return (
    <div className="flex flex-col items-center gap-2">
      <InstructionTitle className="text-left" subtitleText={subtitle || ''} text={label || ''} />
      {rating && (
        <Rating
          max={10}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          size="large"
        />
      )}
      {!rating &&
        options?.map((e, idx) => {
          return (
            <Fragment key={idx}>
              <BaseButton outlined label={e.title} onClick={() => handleClick(e.title)} />
              {e.subtitle && <small className="text-center text-xs text-slate-600">{e.subtitle}</small>}
            </Fragment>
          );
        })}
      {!Boolean(rating) && options && options.length < 1 && (
        <>
          <TextField value={value || ''} multiline placeholder="Enter Here" maxRows={4} variant="filled" fullWidth onChange={(evt) => setValue(evt.target.value)} />
          <BaseButton className="my-2" label="Skip" onClick={() => handleClick('NA')} />
        </>
      )}
      {value && (rating || (!rating && options && options.length < 1)) && <BaseButton outlined onClick={() => handleClick(String(value))} label="Next" />}
    </div>
  );
};

const questions: QuestionProps[] = [
  {
    label: 'How user-friendly was the app for image capture, in terms of navigation and getting comfortable?',
    options: [{ title: 'Easy' }, { title: 'Neutral' }, { title: 'Hard' }],
  },
  {
    label: 'Did the app provide clear instructions and information?',
    options: [{ title: 'Yes' }, { title: 'Partially' }, { title: 'No' }],
  },
  {
    label: `Was the app's feedback helpful in improving the quality of your images?`,
    options: [{ title: 'Yes' }, { title: 'Partially' }, { title: 'No' }],
  },
];
