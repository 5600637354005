import Backdrop from '@mui/material/Backdrop';

type Props = {
  open: boolean;
  handleClose?: () => void;
};

export default function Loading({ open, handleClose }: Props) {
  return (
    <Backdrop
      sx={{
        background: 'rgba(256, 256, 256, 0.9)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      data-testid="loading-component">
      <img width={50} alt="Loading Icon" data-testid="loading-icon" src={`${process.env.PUBLIC_URL}/Loading.gif`} />
    </Backdrop>
  );
}
