import React from 'react';
import Button from '@mui/material/Button';
import Text from 'src/components/Text/Text';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface PhotoData {
  left?: string;
  right?: string;
}

interface Props {
  data: {
    photos: { [key: string]: PhotoData };
  };
  active: number;
  checklist: string[];
  handleFinish: () => void;
  setMode: (mode: 'left' | 'right' | 'preview' | 'complete' | 'finish' | 'single') => void;
}

const CompleteReview: React.FC<Props> = ({ data, active, checklist, handleFinish, setMode }) => {
  return (
    <div className="flex flex-col h-full justify-between">
      <Card>
        <CardContent>
          <div className="flex flex-col gap-2">
            <Text align="left" variant="title" extraClassname="font-semibold">
              Your Photos
            </Text>
            <div className="flex gap-2 w-full">
              <div>
                <img className="w-full h-auto" src={data?.photos[active]?.left} alt="Left" />
              </div>
              <div>
                <img className="w-full h-auto" src={data?.photos[active]?.right} alt="Right" />
              </div>
            </div>
            <div className="border-2 p-2 border-blue-500 text-blue-500 w-full rounded-lg">
              <h6 className="font-bold text-center">Ensure the photos include the following</h6>
              {checklist.map((e) => (
                <div key={e} className="flex gap-2 items-center">
                  <CheckCircleIcon className="text-xs" /> <small>{e}</small>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex flex-col gap-2">
        <Button onClick={handleFinish} variant="contained">
          Upload And Go to Next Photo
        </Button>
        <Button onClick={() => setMode('left')} variant="outlined">
          Retake Photos
        </Button>
      </div>
    </div>
  );
};

export default CompleteReview;
