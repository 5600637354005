import classnames from 'classnames';
import Text from 'src/components/Text/Text';

import type { ReactElement } from 'react';

type Props = {
  img: ReactElement;
  title: string;
  subtitle: string;
  borderBottom: boolean;
};

const TipCard = ({ img, title, subtitle, borderBottom }: Props) => {
  return (
    <div
      className={classnames('flex py-10 justify-between', {
        'border-b': borderBottom,
      })}>
      <div className="w-[60%]">
        <div className="pb-3">
          <Text align="left" variant="subtitle" weight="medium" extraClassname="text-[#001D47]">
            {title}
          </Text>
        </div>
        <Text align="left" variant="paragraph">
          {subtitle}
        </Text>
      </div>
      <div className="w-[35%]">{img}</div>
    </div>
  );
};

export default TipCard;
