import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RedirectPage = () => {
  const navigate = useNavigate();
  const { claimId, captureType } = useParams();

  useEffect(() => {
    if (claimId === 'recapture') return;
    if (claimId !== 'damage') navigate(`/${captureType}/${claimId}`);
  }, [claimId, captureType, navigate]);

  return <></>;
};

export default RedirectPage;
