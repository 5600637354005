import React, { lazy, useEffect, useState } from 'react';
import ContentView from 'src/components/Content/Content';
import BrandingHeader from 'src/components/BrandingHeader/BrandingHeader';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import Text from 'src/components/Text/Text';
import Warning from '@mui/icons-material/Warning';
import capitalize from 'lodash/capitalize';
import Footer from 'src/components/Footer/Footer';
import BaseButton from 'src/components/BaseButton/BaseButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useFeatureStore } from 'src/stores/featureStore';
import InstructionalVideo from 'src/components/InstructionalVideo/InstructionalVideo';
import { dexieDb } from 'src/utils/indexedDb';

const ALLOWED_STATUS = ['Initiated', 'Not Processable'];

const RecaptureLanding = () => {
  const claimInfo = useClaimInfoStore();
  const { claimId, roomId, captureType } = useParams();
  const entityConfig = useEntityConfigStore((state) => state.config);
  const [IconComponent, setIconComponent] = useState<any>(null);
  const instructionalVideo = useFeatureStore((state) => state.instructionalVideo);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (claimInfo.recaptureInfo?.roomType) {
      const roomType = capitalize(claimInfo.recaptureInfo?.roomType?.split(' ')[0] || 'other');
      setIconComponent(lazy(() => import(`src/components/icons/rooms/${roomType}`)));
    }
  }, [claimInfo.recaptureInfo?.roomType]);

  const handleClearDb = async () => {
    await dexieDb.pictures.clear();
  };

  const handleNext = () => {
    handleClearDb();
    if (instructionalVideo) setOpen(true);
    else handleNavigate();
  };

  const handleNavigate = () => {
    if (claimId && roomId) {
      navigate(`/${captureType}/${claimId}/${roomId}/tips`);
    }
  };

  const isSubmitAllowed = ALLOWED_STATUS.includes(claimInfo.recaptureInfo?.status || '');

  return (
    <ContentView className="gap-2 min-h-screen -mt-14 pt-14 landscape:pb-24" variant="secondary">
      <>
        <InstructionalVideo actionLabel="Fix Images" handleAction={handleNavigate} isOpen={open} />
        <div className="flex flex-col">
          <div className="flex flex-col items-center pb-8">
            <BrandingHeader logo={claimInfo?.logo || ''} logoVerified={claimInfo?.logoVerified} hideHostaBranding={entityConfig.hideHostaBranding} />
            <div className="flex flex-col gap-3">
              <Text variant="title">New Photos Needed</Text>
              <Text extraClassname="text-justify" variant="paragraph">
                We need you to retake wall photos for your <b className="capitalize">{claimInfo.recaptureInfo?.roomType}</b>.
              </Text>
              <Text extraClassname="text-justify" variant="paragraph">
                Photos that provide a complete picture allow us to create an overview of the damage along with room measurements to accurately assess your claim.
              </Text>
              <div className="flex justify-center">
                <div className="flex flex-col bg-slate-200 gap-4 rounded-lg p-4">
                  <Text variant="paragraph">
                    <Warning color="error" /> Needs Attention
                  </Text>
                  <div className="flex justify-center">
                    <div className="flex flex-col items-center bg-white rounded-lg p-2">
                      {IconComponent && <IconComponent className="stroke-icons-color" />}
                      <Text variant="subtitle">{capitalize(claimInfo?.recaptureInfo?.roomType || 'other')}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer>
          {!isSubmitAllowed && (
            <small className="text-center text-red-600">
              Cannot submit images to this room while the status is <b>{claimInfo?.recaptureInfo?.status || 'Unknown'}</b>
            </small>
          )}
          <BaseButton disabled={!isSubmitAllowed} onClick={handleNext} label="Next" />
        </Footer>
      </>
    </ContentView>
  );
};

export default RecaptureLanding;
