const texts = {
  error: 'Error',
  selectAnOptionToProgress: 'Please select an option to progress to the next step',
  OK: 'OK',
  retakePhoto: 'Retake photo',
  addPhoto: 'Add photo',
  dateEnteredNotValid: 'The date entered is not valid',
  notSure: "I'm not sure",
  poweredBy: 'Powered by',
  back: 'Back',
  restart: 'Restart',
  skip: 'Skip',
  readyToStart: "I'm Ready to Start!",
  gotQuestions: 'Have Questions? See our tips',
  here: 'here',
  tip: 'Tip',
  confirmPhotosPageInstruction: 'Please confirm each photo contains a full wall',
  confirmPhotosPageSecondaryInstruction: 'Each image should contain a main wall, four corners, the ceiling and the floor.',
  looksGood: 'Next',
  damageSelectPageInstruction: 'What type of damage did you experience?',
  next: 'Next',
  dateOfDamageSelectPageInstruction: 'When did the loss occur?',
  floorDamagePageInstruction: 'Is the water impacting multiple floors in the property?',
  roomCapturePageInstruction: 'Choose a wall to be the first photo',
  roomCapturePageSecondaryInstruction: 'Close all doors and make sure the entire wall, including corners, is captured.',
  showTheTips: 'Show me the tips',
  roomSelectPageTitle: 'Where is your damage?',
  roomSelectPageInstruction: 'If you have damage in multiple rooms, you will be able to add additional rooms and damage later.',
  tip1Text: 'Pick one wall to start with. Stand back to get a photo of each wall and the edges',
  tip2Text: 'Make sure the ceiling, the floor, and all four corners are in the photo',
  tip3Text: 'Hold the camera straight on and avoid blurry shots',
  tip4Text: 'Capture walls in a clockwise direction, moving from left to right',
  tip5Text: 'If you have an open concept kitchen/dining room, you can picture imaginary walls separating the kitchen and dining room space.',
  tip5SecondaryText: 'Then, take pictures of the space as if it were two rooms',
  whereDidTheWaterComeFromPageInstruction: 'Where did the water come from?',
  insideTheHouse: 'Inside the house',
  outsideTheHouseSecondary: 'Ex. A hurricane, flood, rain, hail',
  insideTheHouseSecondary: 'Ex. Pipes, bathroom, kitchen',
  outsideTheHouse: 'Outside the house',
  yes: 'Yes',
  no: 'No',
  bathroom: 'bathroom',
  bedroom: 'bedroom',
  kitchen: 'kitchen',
  basement: 'basement',
  living: 'living room',
  laundry: 'laundry',
  sunroom: 'sunroom',
  dining: 'dining room',
  other: 'other',
  garage: 'garage',
  wall: 'Wall',
  photo: 'Photo',
  office: 'Office',
  waterCleanlinessPageInstruction1: 'Is the water clear and clean?',
  waterCleanlinessPageInstruction2: 'Does the water come from a dishwasher, washing machine, contains urine, or is from an aquarium?',
  waterCleanlinessPageInstruction3: 'Does the water contain fecal matter or toxins?',
  whatDoesThisMean: 'What does this mean?',
  isTheWaterClean: 'Is the water clear and clean?',
  waterCleanlinessPageTooltip1FirstParagraph: 'Clear and clean water originates from a sanitary water source and does not pose a substantial risk from touching your skin or being drank.',
  waterCleanlinessPageTooltip2FirstParagraph:
    'Say "Yes" to this question if the water that caused the damage comes from (1) dishwasher/washing machine discharge or overflow (2) Toilet bowl overflow with urine (3) Broken aquariums (4) Punctured water beds.',
  waterCleanlinessPageTooltip3FirstParagraph:
    'Say "Yes" to this question if the water is from: (1) Sewage (2) Waste line backflows originating from beyond the trap (3) Seawater (4)  Rising water from rivers/streams (5) Wind-driven rain from hurricanes/tropical storms',
  waterCleanlinessPageTooltip1SecondParagraph: 'Examples of this is when water comes from (1) water supply lines, (2) melting ice or snow, (3) rainwater (4) toilet tanks  (5) empty toilet bowls',
  waterCleanlinessPageTooltip2SecondParagraph: 'We are asking this question because water we take special care to disinfect the area from water that comes from these sources.',
  waterCleanlinessPageTooltip3SecondParagraph:
    'We are asking this question because we take special care to disinfect the area from water that comes from these highly contaminated sources because they can be harmful to humans if contacted or consumed.',
  selectPhotoForDamageCaptureInstruction: "Tap a photo that contains damage you'd like to document",
  closeupDamageCapturePageInstruction: 'Time for the close up!',
  closeupDamageCapturePageInstructionSecondary: 'Stand 1 to 3 feet away from the damage and take a zoomed-in photo that shows details',
  photosYouAlreadyTook: 'Photos you already took',
  zoomedOutDamageCapturePageInstruction: 'Now, take a big step back and take a zoomed out photo of the damage',
  closeUpPhoto: 'Close up photo',
  fullWallPhoto: 'Full wall photo',
  zoomedOutPhoto: 'Zoomed out photo',
  fullWall: 'Full wall',
  '1ftAway': '1-3 ft away',
  '4ftAway': '4-6 ft away',
  damageCaptureTipsPageInstruction: "Now, it's time to focus on capturing damage. We are going to ask you to take 2 more photos.",
  damageCaptureTip1Text: 'Close up to damage (1-3ft away)',
  damageCaptureTip2Text: '4-6 feet away from damage',
  waterStillLeaking: 'Is the water still leaking?',
  plumberContacted: 'If the water is still leaking, have you contacted a plumber?',
  waterHasMold: 'Can you see any mold growing from the water damage?',
  instructionSteps1Text: 'Select room you would like to capture',
  instructionSteps2Text: 'Capture photos',
  instructionSteps3Text: 'Submit for review',
  instructionStepsTitle: 'Three simple steps and then we will take it from there',
  instructionStepsClarification: 'About 2 minutes per room',
  yesLooksGood: 'Yes, looks good',
  damageCapturePhotoPreviewPageInstruction: 'Do the photos show a full wall, a close up, and a zoomed out?',
  retakeMyPhotos: 'Retake my photos',
  yourPhoto: 'Your photo',
  example: 'Example',
  captureMoreDamagesPageInstruction: 'Do you want to capture more damages?',
  needToAddMore: 'I need to add more',
  iAmDone: 'I’m done',
  continue: 'Continue',
  addMoreDamages: 'Add more damages',
  everythingIsCaptured: 'everything is captured',
  roomSubmittedPageInstruction: `Your damage and room photos have been submitted!`,
  roomSubmittedPageSubtitle: 'Hosta AI will review your submission. \n If any of your photos can not be \n processed, you will receive an email \n or a text within 10 minutes with next steps.',
  roomSubmittedPageSubtitleFeedback: 'Your feedback on this experience is \n important to us. Please take 1-2 \n minutes to share your thoughts.',
  roomSubmittedPageClose: 'You may now close this window.',
  roomSubmittedAddMoreTitle: 'Do you have another room \n with damage?',
  roomSubmittedAddMoreSubtitle: 'Is there another room in your home \n that has damage related to this \n insurance claim?',
  confirmPhotosTitle: 'Can you see every edge in \n each wall photo?',
  confirmPhotosSubtitle: 'Please confirm each wall photo shows \n every edge of the wall. If not, please \n retake the photo.',
  finish: 'Finish!',
  addAnotherRoom: 'add another room',
  reportReceivedPageInstruction: 'We’ve received your report, and we are on our way to get you back to whole.',
  reportReceivedPageInstructionSecondary: 'Would you like to add another damaged area?',
  mustSelectAnswer: 'Must select an answer',
  mustAddAtLeast4Walls: 'Must add at least 4 walls',
  mustSelectAWall: 'Must select a wall',
  mustAddZoomedInPhoto: 'Must add a zoomed in damage photo',
  mustAddZoomedOutPhoto: 'Must add a zoomed out damage photo',
  selectAnAnswer: 'Select an answer',
  mustAnswerAllQuestions: 'Must answer all questions',
  tip1Bullet1: 'Close all the doors in the room.',
  tip1Bullet2: 'Pick one wall to start with.',
  tip1Bullet3: 'Stand back to get a photo of each wall and the edges.',
  tip1Bullet4: 'Make sure the ceiling, the floor, and all four corners are in the photo.',
  tip1Bullet5: 'Zoom out your camera as much as you can.',
  tip1Bullet6: 'Portrait or landscape photos are fine. What ever is easier to fit the whole wall.',
  tip1Bullet7: 'No need to move furniture or tidy up.',
  tipModalTitle: 'Let’s capture your entire room first. Later we will get pictures of your damage.',
  tipModalText: 'In order to make a floor plan, we’ll need  to first have zoomed out pictures of your walls. In later steps we will get zoomed in pictures so you can show us your damage.',
  iUnderstand: 'I Understand',
  cameraTipsPageInstructionText: 'Pinch in to activate your wide angle camera',
  cameraTipsPageInstructionSubtitle: 'Most phones purchased after 2018 have this feature.',
  cameraTipsPageBottomText: 'This will help you easily photograph the entire wall, ceiling, and floor.',
  damageLandingTitle: "We're sorry to hear of your troubles",
  damageLandingSubTitle: (displayName: string) => `${displayName} has partnered with Hosta AI to capture damage of your room(s) to expedite your claim.`,
  damageLandingFooter1: 'Security is important to us.',
  damageLandingFooter2: 'Your information is private and protected.',
  damageLandingStartProcess: 'Start Process',
  damageInstructionTitle1: "Three simple steps and then we'll take it from there",
  damageInstructionSubTitle1: 'Typically takes just 3 minutes per room',
  damageInstructionTitle2: 'First step completed',
  damageInstructionSubTitle2: null,
  damageInstructionTitle3: 'Second step completed',
  damageInstructionSubTitle3: null,
  damageInstructionButtonStep1: 'Go to Step 1',
  damageInstructionButtonStep2: 'Go to Step 2',
  damageInstructionButtonStep3: 'Go to Step 3',
  confirmDamageTitle: 'Do you have more damage in this room?',
  confirmDamageSubtitle: 'Is there another area of your room \n that has damage related to this \n insurance claim?',
  damageCaptureTitle: "Next, we'll capture images of the room",
  damageCaptureSubTitle: "After this, we'll capture up-close images of the damage",
  damageRoomSelection: "Select the room type you'd like to capture",
  captureDamageExampleTitle: 'Your insurance provider needs two photos of your damage',
  captureDamageExampleSubtitle: 'This will provide an accurate understanding of the extent of your damage.',
  captureDamageExampleNextButton: 'Start Damage Capture',
  captureDamageExampleTipsOneTitle: 'Photo 1: Close Up',
  captureDamageExampleTipsOneSubtitle: '1-3 feet away \n Show detail of damage',
  captureDamageExampleTipsTwoTitle: 'Photo 2: Context',
  captureDamageExampleTipsTwoSubtitle: '4-6 feet away \n Show context of room',
  wallSelectionTitle: 'Select the wall photos where \n you can see your damage.',
};

export const analytics = {
  beginButton: 'Landing -> Begin',
  selectRoom: 'Select Room',
  readyToStart: 'Instructions -> Ready To Start',
  captureDamage: 'Instructions Step 1 Completed  -> Capture Damage Images',
  captureDamageExample: 'Damage Example -> Capture Damage Images',
  addMoreDamages: 'Capture Damages -> Add More Damages',
  doneWithDamages: 'Capture Damages -> Done With Damages',
  captureRoomImages: 'Instructions Step 2 Completed -> Capture Room Images',
  captureRoomExample: 'Capture Room Example -> Got It',
  nextImageCapture: 'Capture Room -> Next Image',
  prevImageCapture: 'Capture Room -> Prev Image',
  roomComplete: 'Capture Room -> Complete Room',
  reviewRoom: 'Instructions Step 3 Completed -> Review Room',
  nextStep: 'Review Room -> Next',
  looksGood: 'Collage Image -> Looks Good',
  addAnotherRoom: 'Final -> Add Another Room',
  addAnotherWall: 'Capture Room -> Add Another Wall',
  addPhoto: 'Add photo',
  retakePhoto: 'Retake photo',
  deletePhoto: 'Delete photo',
  skipTips: 'Skip tips',
  tipsPageNext: 'Tips page -> Next',
  tipsPagePrev: 'Tips page -> Prev',
  tipsPageOnComplete: 'Tips page -> On complete',
  openTipsPage: 'Capture Room -> Open tips page',
  instructionalVideoAction: 'Instructional Video Action',
  instructionalVideoComplete: 'Instructional video complete',
  instructionalVideoPlayStarted: 'Instructional video play started',
};

export default texts;
