import { useClaimInfoStore } from 'src/stores/claimInfo';

const claimId = () => {
  const claimInfo = useClaimInfoStore.getState();
  return {
    type: 'context',
    elements: [
      {
        type: 'mrkdwn',
        text: `*Claim id:*\n<${process.env.REACT_APP_BASE_URL}/#/app/allroom/${claimInfo.claimId}|${claimInfo.claimId}>`,
      },
    ],
  };
};

export default claimId;
