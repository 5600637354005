import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { analytics } from 'src/texts/texts';
import { useLocalStorage } from 'usehooks-ts';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import Text from 'src/components/Text/Text';

import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';

const LandingPage = () => {
  const { claimId, captureType } = useParams();
  const [cachedRoomId] = useLocalStorage<string | null>('cachedRoomId', null);

  useLocalStorage('test', captureType === 'test');

  const navigate = useNavigate();

  useEffect(() => {
    if (cachedRoomId && navigator.onLine) {
      localStorage.removeItem('cachedRoomId');
      navigate(`/${captureType}/${claimId}/${cachedRoomId}/confirm-photos`, {
        relative: 'path',
      });
    }
  }, [cachedRoomId, captureType, claimId, navigate]);

  const navigateToRoomSelect = () => {
    log.event({
      event: eventNames.START_PROCESS,
      data: { page: 'Layout Capture Landing Page', env: process.env.REACT_APP_ENV },
    });
    navigate(`/${captureType}/${claimId}/room-select`, {
      relative: 'path',
    });
  };

  return (
    <ContentView className="min-h-[100dvh] -mt-14 pt-14 landscape:pb-24" variant="secondary">
      <div>
        <div className="pb-4">
          <Text variant="title">Introduction</Text>
        </div>
        <Text variant="subtitle" align="left">
          For this study you will be asked to photograph an entire room using the camera in your phone.
        </Text>
        <Text variant="subtitle" align="left" extraClassname="mt-6">
          Please be sure to remove any sensitive information from your space.
        </Text>
        <Text variant="subtitle" align="left" extraClassname="mt-6">
          Follow the on screen instructions until you successfully submit your photos.
        </Text>
      </div>

      <Footer showAppVersion>
        <BaseButton dataTestId="start-process" name={analytics.beginButton} onClick={navigateToRoomSelect} label="start" />
      </Footer>
    </ContentView>
  );
};

export default LandingPage;
