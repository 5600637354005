import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type State = {
  eventQueue: any[];
};

type UploaderStore = State & {
  add: (event: any) => void;
  clear: () => void;
  remove: (event: any) => void;
};

export const useUploadQueueStore = create<UploaderStore>()(
  subscribeWithSelector((set, get) => ({
    eventQueue: [],
    add: (event) => {
      const exist = get().eventQueue?.find((evt) => evt.id === event.id);
      if (!exist) {
        set({ eventQueue: [...get().eventQueue, event] });
      }
    },
    remove: (event) => {
      set({ eventQueue: get().eventQueue.filter((e) => e.id !== event.id) });
    },
    clear: () => {
      for (const evt of get().eventQueue) {
        // Cancel all the unfulfilled HTTP requests
        // as these are going to be handed over to the room submit event.
        evt.abortController.abort('Canceled from queue store');
      }
      set({ eventQueue: [] });
    },
  }))
);
