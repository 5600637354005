import { ErrorBoundary } from 'react-error-boundary';
import { PropsWithChildren } from 'react';
import GenericError from '../ErrorFallback/Generic';
import * as log from 'src/utils/logger';

const ErrorElement = ({ children }: PropsWithChildren) => (
  <ErrorBoundary
    fallback={<GenericError />}
    onError={(error, info) => {
      log.error({ event: 'Rendering error', data: { error, info } });
    }}>
    {children}
  </ErrorBoundary>
);

export default ErrorElement;
