import React from 'react';
import Button from '@mui/material/Button';
import Text from 'src/components/Text/Text';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { checklist } from 'src/recapture/utils';

interface FinishProps {
  data: {
    photos: { left: string; right: string }[];
  };
  active: number;
  handleRetake: () => void;
  handleSubmit: () => void;
}

const FinishCapture: React.FC<FinishProps> = ({ data, active, handleRetake, handleSubmit }) => {
  return (
    <div className="flex flex-col h-full justify-between">
      <Card>
        <CardContent>
          <div className="flex flex-col gap-2">
            <Text align="left" variant="title" extraClassname="font-semibold">
              Your Photos
            </Text>
            <div className="flex gap-2 w-full">
              <div>
                <img className="w-full h-auto" src={data?.photos[active]['left']} alt="Left" />
              </div>
              <div>
                <img className="w-full h-auto" src={data?.photos[active]['right']} alt="Right" />
              </div>
            </div>
            <div className="border-2 p-2 border-blue-500 text-blue-500 w-full rounded-lg">
              <h6 className="font-bold text-center">Ensure the photos include the following</h6>
              {checklist.map((e) => {
                return (
                  <div key={e} className="flex gap-2 items-center">
                    <CheckCircleIcon className="text-xs" /> <small>{e}</small>
                  </div>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex flex-col gap-2">
        <small className="text-center">No More Photos are needed for this room.</small>
        <Button onClick={handleSubmit} variant="contained">
          Upload And Proceed
        </Button>
        <Button onClick={handleRetake} variant="outlined">
          Retake Photos
        </Button>
      </div>
    </div>
  );
};

export default FinishCapture;
