import React, { useState } from 'react';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import DamageHeader from 'src/components/Header/DamageHeader';
import ContentView from 'src/components/Content/Content';
import Text from 'src/components/Text/Text';
import TakePhotoFooterButton from 'src/components/TakePhotoFooterButton';
import PreviewPhoto from 'src/components/PreviewPhoto';
import YesNoButtons from './YesNoButtons';

type Props = {
  handleComplete: (imgData: string[]) => void;
  handleCancel: () => void;
};

const TwoPhotosCapture = ({ handleComplete, handleCancel }: Props) => {
  const [photos, setPhotos] = useState({
    photo1: undefined,
    photo2: undefined,
  });
  const entityConfig = useEntityConfigStore((state) => state.config);
  const captureImageCopy = useEntityConfigStore((state) => state.copy.captureImage);

  const handleImage = (type: string, data: string) => {
    setPhotos({ ...photos, [type]: data });
  };

  if (!photos.photo1) {
    return (
      <>
        <DamageHeader handleBack={handleCancel} />
        <ContentView>
          <Text variant="title">{captureImageCopy.retake.twoPhotos.slide1.title}</Text>
          <Text variant="subtitle" extraClassname="pt-8 pb-4">
            Capture the far edge of your wall, and include a wall object - artwork, a window, or even a sticky note would work.
          </Text>
          <img alt="" className="w-full p-3" src="/images/small-room/photo-1.svg" />
          <TakePhotoFooterButton label="take photo 1" eventName="TODO: create event name" onCapture={(d) => handleImage('photo1', d)} capFirstLetterOnly={entityConfig.capFirstLetterOnly} />
        </ContentView>
      </>
    );
  }
  if (!photos.photo2) {
    return (
      <>
        <DamageHeader handleBack={handleCancel} />
        <ContentView>
          <Text variant="title">{captureImageCopy.retake.twoPhotos.slide2.title}</Text>
          <Text variant="subtitle" extraClassname="pt-8 pb-4">
            Now, capture the missing edge(s) from your last photo of this wall. Include the same wall object.
          </Text>
          <img alt="" className="w-full p-3" src="/images/small-room/photo-2.svg" />
          <TakePhotoFooterButton label="take photo 2" eventName="TODO: create event name" onCapture={(d) => handleImage('photo2', d)} capFirstLetterOnly={entityConfig.capFirstLetterOnly} />
        </ContentView>
      </>
    );
  }
  return (
    <>
      <DamageHeader handleBack={handleCancel} />
      <ContentView className="pb-48">
        <Text variant="title">Can you see every edge ?</Text>
        <Text variant="subtitle" extraClassname="mt-4">
          Confirm the photos you took include every edge of the wall, that they overlap, and include a wall object
        </Text>
        <PreviewPhoto
          id={1}
          label={captureImageCopy.retake.twoPhotos.slide1.title}
          imageData={photos.photo1}
          showRetake
          handleCapture={(d) => handleImage('photo1', d)}
          capFirstLetterOnly={entityConfig.capFirstLetterOnly}
        />
        <PreviewPhoto
          id={1}
          label={captureImageCopy.retake.twoPhotos.slide2.title}
          imageData={photos.photo2}
          showRetake
          handleCapture={(d) => handleImage('photo2', d)}
          capFirstLetterOnly={entityConfig.capFirstLetterOnly}
        />
        <YesNoButtons bordered handleYes={() => handleComplete([photos.photo1 || '', photos.photo2 || ''])} handleNo={handleCancel} />
      </ContentView>
    </>
  );
};

export default TwoPhotosCapture;
