import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export interface IFooterProps {
  extraClassname?: string;
  hideOnDesktop?: boolean;
  showAppVersion?: boolean;
}

const Footer = ({ hideOnDesktop, extraClassname, children, showAppVersion = false }: PropsWithChildren<IFooterProps>) => {
  return (
    <div
      data-testid="footer-wrapper"
      className={classNames(`${extraClassname || ''}`, 'max-w-[500px] z-10 fixed w-full flex items-center justify-center bottom-0', {
        'desktops:hidden': hideOnDesktop,
      })}>
      <div className="w-full flex flex-col items-center justify-center space-y-2 px-4 pb-4">
        {children}
        {showAppVersion && <small className="absolute right-2 bottom-0 text-xs text-slate-400">Version::{process.env.REACT_APP_VERSION}</small>}
      </div>
    </div>
  );
};

export default Footer;
