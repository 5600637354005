import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Text from 'src/components/Text/Text';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HelpContext from 'src/components/HelpModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocalStorage } from 'usehooks-ts';

interface SinglePhotoReviewProps {
  data: {
    photos?: { [key: string]: { one?: string } };
  };
  active: number;
  getNextId: () => number;
  handleSingleItemFinish: () => void;
  handleOnePhoto: () => void;
  checklist: string[];
}

const SinglePhotoReview: React.FC<SinglePhotoReviewProps> = ({ data, active, getNextId, handleSingleItemFinish, handleOnePhoto, checklist }) => {
  const [showTip, setShowTip] = useState(false);
  const [tipsCount, setTipsCount] = useLocalStorage<number>('tipsCount', 0);

  useEffect(() => {
    setShowTip(false);
  }, [data]);

  const showTipHandler = () => {
    if (tipsCount >= 2) {
      handleOnePhoto();
      return;
    }
    setTipsCount(tipsCount + 1);
    setShowTip(true);
  };

  return (
    <div className="flex flex-col h-full justify-between">
      <div className="flex flex-col gap-2">
        <Card>
          <CardContent>
            <div className="flex flex-col gap-2">
              <Text align="left" variant="subtitle" extraClassname="font-semibold">
                Your Photo
              </Text>
              <img src={data?.photos?.[active]?.one} alt="Single" />
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="border-2 p-2 border-blue-500 text-blue-500 w-full rounded-lg">
        <h6 className="font-bold text-center">Ensure the photos include the following</h6>
        {checklist.map((e) => (
          <div key={e} className="flex gap-2 items-center">
            <CheckCircleIcon className="text-xs" /> <small>{e}</small>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <HelpContext open={showTip} onClose={() => setShowTip(false)} onSubmit={handleOnePhoto} />
        <div className="flex flex-col gap-2">
          <small className="text-center">{getNextId() === -1 && 'No more photos are needed for this room.'}</small>
          <Button onClick={handleSingleItemFinish} variant="contained">
            {getNextId() === -1 ? 'Upload and Proceed' : 'Upload And Go to Next Photo'}
          </Button>
          <Button onClick={showTipHandler} variant="outlined">
            Retake Photo
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SinglePhotoReview;
