import React from 'react';
import Button from '@mui/material/Button';
import Text from 'src/components/Text/Text';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImagePreview from 'src/components/ImagePreview';

interface PhotoReviewComponentProps {
  roomType: { left: string; right: string };
  data: {
    photos?: { [key: string]: { left?: string; right?: string } };
  };
  active: number;
  handleComplete: () => void;
  handleRightPhoto: () => void;
}

const PhotoReviewComponent: React.FC<PhotoReviewComponentProps> = ({ roomType, data, active, handleComplete, handleRightPhoto }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-2">
        <Card>
          <CardContent>
            <div className="flex flex-col gap-2">
              <Text align="left" variant="subtitle" extraClassname="font-semibold">{`${roomType.right}`}</Text>
              <img src={data?.photos?.[active]?.right} alt="Right" />
            </div>
          </CardContent>
        </Card>
        <ImagePreview label={roomType.left} url={data?.photos?.[active]?.left || ''} />
        <Text align="center" variant="paragraph">
          Can you see corners and some of the floor and ceiling in this photo?
        </Text>
      </div>
      <div className="flex flex-col gap-2">
        <Button onClick={handleComplete} variant="contained">
          Yes, Looks Good
        </Button>
        <Button onClick={handleRightPhoto} variant="outlined">
          No, Retake Photo
        </Button>
      </div>
    </div>
  );
};

export default PhotoReviewComponent;
