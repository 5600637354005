import { useCallback, useEffect } from 'react';

const useScrollUp = (lister: any, behavior: 'smooth' | 'auto' = 'auto', wait = 0) => {
  const scrollUp = useCallback(() => window.scrollTo({ top: 0, left: 0, behavior }), [behavior]);

  useEffect(() => {
    setTimeout(() => {
      scrollUp();
    }, wait);
  }, [lister, wait, scrollUp]);

  return { scrollUp };
};

export default useScrollUp;
