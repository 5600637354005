import { useState, useEffect, useCallback } from 'react';
import defaultConfig from 'src/entities/default.json';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import WebFont from 'webfontloader';

import { applyTheme } from 'src/utils/theme/theme';

const useEntity = (entity?: string) => {
  const [loaded, setLoaded] = useState(false);
  const setEntityData = useEntityConfigStore((state) => state.setEntityData);

  const loadFonts = async (fontFamilies: string) => {
    return new Promise((resolve) => {
      if (fontFamilies) {
        WebFont.load({
          google: {
            families: fontFamilies.split(','),
          },
          fontactive: () => resolve(true),
          fontinactive: () => resolve(false),
        });
      } else {
        resolve(true);
      }
    });
  };

  const applyConfig = useCallback(
    async (data?: any) => {
      // Create a new object with the default config stuff in case some fields do not exist in the custom config file.
      const configData = data ? { ...defaultConfig, ...data } : defaultConfig;
      const fontsLoaded = await loadFonts(configData.theme.fontFamilies);
      setEntityData({ copy: configData.copy, config: configData.config });
      applyTheme({
        ...configData.theme,
        // use default fonts if there is an error while loading
        fontFamilies: fontsLoaded ? configData.theme.fontFamilies : '',
      });
      setLoaded(true);
    },
    [setEntityData]
  );

  const loadSettings = useCallback(
    (name: string) => {
      if (name !== 'default') {
        import(`src/entities/${name}.json`)
          .then(async (data) => applyConfig(data))
          .catch(() => {
            loadSettings('default');
          });
      } else {
        (async () => applyConfig())();
      }
    },
    [applyConfig]
  );

  useEffect(() => {
    entity && loadSettings(entity);
  }, [entity, loadSettings]);

  return loaded;
};

export default useEntity;
