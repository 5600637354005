import { useEntityConfigStore } from 'src/stores/entityConfig';
import Text from 'src/components/Text/Text';
import classnames from 'classnames';
import numberToOrdinalWord from 'src/utils/numberToOrdinalWord';

type Props = {
  imageNumber: number;
};

const CaptureTitle = ({ imageNumber }: Props) => {
  const entityConfig = useEntityConfigStore((state) => state.config);
  const captureImageCopy = useEntityConfigStore((state) => state.copy.captureImage);
  return (
    <>
      <Text
        variant="title"
        extraClassname={classnames({
          capitalize: !entityConfig.capFirstLetterOnly,
          'first-letter:capitalize': entityConfig.capFirstLetterOnly,
        })}>
        {numberToOrdinalWord(imageNumber)} wall photo
      </Text>
      <Text variant="subtitle" extraClassname="mt-4">
        {imageNumber === 1
          ? captureImageCopy.firstCapture.subtitle
          : captureImageCopy.otherCaptures.subtitle.supplant({
              imageNumber: numberToOrdinalWord(imageNumber).toLowerCase(),
            })}
      </Text>
    </>
  );
};

export default CaptureTitle;
