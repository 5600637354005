import React, { useEffect, useState } from 'react';
import LayoutCapture from 'src/components/layoutCapture/LayoutCapture';
import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import { dexieDb, handleError } from 'src/utils/indexedDb';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Text from 'src/components/Text/Text';
import Footer from 'src/components/Footer/Footer';
import type { IPicture } from 'src/utils/indexedDb';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';

export const getOverViewImages = async (roomId?: string) =>
  dexieDb.pictures
    .where({ roomId: roomId })
    .filter((e) => e.imgType === 'overviewImage')
    .toArray();

export const getTotalPhotos = (photos: IPicture[]) =>
  photos?.reduce((acc, curr) => {
    return acc + 1 + (curr.extraImages?.length || 0);
  }, 0) || 0;

const RoomLayoutCapture = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const overviewImages = useLiveQuery(async () => getOverViewImages(roomId), [roomId]);
  const addImgToQueue = useUploadQueueStore((state) => state.add);

  const [captureOpen, setCaptureOpen] = useState<boolean>(false);

  useEffect(() => {
    if (overviewImages?.length === 0) {
      setCaptureOpen(true);
    }
  }, [overviewImages]);

  const totalPhotos = async () => {
    try {
      const photos = await getOverViewImages(roomId);
      return getTotalPhotos(photos);
    } catch (e) {
      handleError(e);
    }
  };

  const handleAddImages = async (photos: string[]) => {
    const errors: string[] = [];
    const imgId = (overviewImages?.length || 0) + 1;
    const nextId = (await totalPhotos()) || 0;

    try {
      await dexieDb.pictures.add({
        roomId,
        errors,
        id: imgId,
        uploadId: nextId + 1,
        content: photos[0],
        imgType: 'overviewImage',
        ...(photos.length > 1 && { extraImages: [photos[1]] }),
      });
      addImgToQueue({ id: imgId, abortController: new AbortController() });

      log.event({
        event: eventNames.CAPTURE_COMPLETE,
        data: {
          id: imgId,
          roomId: roomId,
          hasExtraImage: photos.length > 1,
        },
      });
    } catch (e) {
      handleError(e);
      log.error({ event: eventNames.CAPTURE_ERROR, data: { error: e } });
    } finally {
      setCaptureOpen(false);
    }
  };

  const onComplete = () => {
    navigate('../room-review');
    log.event({ event: eventNames.COMPLETE_ROOM_CAPTURE, data: { roomId } });
  };

  const openCamera = () => {
    setCaptureOpen(true);
    log.event({ event: eventNames.OPEN_LAYOUT_CAPTURE, data: { roomId } });
  };

  const lastImages = overviewImages && overviewImages?.[overviewImages.length - 1];
  const totalImages = getTotalPhotos(overviewImages || []);

  return (
    <>
      <ContentView className="gap-2 min-h-screen -mt-14 pt-14 landscape:pb-24" variant="secondary">
        <div>
          <div className="pb-3">
            <Text variant="title">Next Photo</Text>
          </div>
          <Text variant="subtitle">Move along the perimeter of your room to capture your next image. Ensure there is overlap from the previous image.</Text>
        </div>
        {lastImages ? (
          <div className="w-full min-h-[45vh] mt-14 flex flex-row relative items-center justify-center mb-10">
            <div className="flex flex-col">
              <div className="w-[55%] overflow-hidden">
                <img alt="test" src={lastImages.content} className="w-full" />
              </div>

              {lastImages?.extraImages?.[0] ? (
                <div className="w-[55%] overflow-hidden">
                  <img alt="test" src={lastImages.extraImages[0]} className="w-full" />
                </div>
              ) : null}
            </div>

            {!lastImages?.extraImages?.[0] ? (
              <div className="border-2 border-dashed border-black absolute h-[105%] -top-[2.5%] flex flex-col rounded-lg bg-white/50 w-[55%] right-0 justify-center items-center">
                <p className="text-center py-4">Next Photo</p>
              </div>
            ) : (
              <div className="absolute h-[105%] w-[55%] -top-[2.5%] flex flex-col right-0 justify-between items-center">
                <div className="flex flex-col justify-center items-center w-full border-2 border-dashed border-black h-[50%] rounded-lg bg-white/50 mb-1">
                  <p className="text-center py-4">Next Photo</p>
                </div>
                <div className="flex flex-col justify-center items-center w-full border-2 border-dashed border-black h-[50%] rounded-lg bg-white/50">
                  <p className="text-center py-4">Next Photo</p>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <Footer extraClassname="bg-screen-bg-secondary pt-2">
          {(overviewImages?.length || 0) >= 4 ? <BaseButton outlined dataTestId="complete-room-capture-btn" label="Complete Room Capture" onClick={onComplete} className="mt-2" /> : null}
          <BaseButton lightBg type="button" disabled={totalImages >= 16} onClick={openCamera} icon="/icons/camera@2x.png" label="take next photo" />
        </Footer>
      </ContentView>
      {captureOpen && <LayoutCapture isOpen={captureOpen} onClose={() => setCaptureOpen(false)} onComplete={handleAddImages} captureMode={lastImages?.extraImages?.[0] ? 'two-photos' : 'one-photo'} />}
    </>
  );
};

export default RoomLayoutCapture;
