import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { IS_TEST_ENV } from 'src/utils/utils';

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

export const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 0,
};

const baseAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

baseAxios.interceptors.request.use((request) => {
  return (request as RetryConfig)?.retry === undefined ? { ...request, ...globalConfig } : request;
});

baseAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const isInvalidLinkStatus = response.status === 422;

    const isStatusCodeInRange = (response.status >= 400 && response.status <= 500) || error.code === 'ERR_NETWORK';

    if (!config || !config.retry || !isStatusCodeInRange || isInvalidLinkStatus || IS_TEST_ENV) {
      return Promise.reject(error);
    }

    config.retry -= 1;
    config.retryDelay += 1000;

    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => baseAxios(config));
  }
);

export default baseAxios;
