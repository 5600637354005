interface IPhoneModel {
  [vendor: string]: { model: string; resolution: string }[];
}

export const phoneModels: IPhoneModel = {
  Apple: [
    {
      model: 'iPhone SE (1st generation)',
      resolution: '640 x 1136',
    },
    {
      model: 'iPhone 6',
      resolution: '750 x 1334',
    },
    {
      model: 'iPhone 6 Plus',
      resolution: '1080 x 1920',
    },
    {
      model: 'iPhone 7',
      resolution: '750 x 1334',
    },
    {
      model: 'iPhone 7 Plus',
      resolution: '1080 x 1920',
    },
    {
      model: 'iPhone X',
      resolution: '1125 x 2436',
    },
    {
      model: 'iPhone XS',
      resolution: '1125 x 2436',
    },
    {
      model: 'iPhone XR',
      resolution: '828 x 1792',
    },
    {
      model: 'iPhone 11',
      resolution: '828 x 1792',
    },
    {
      model: 'iPhone 11 Pro',
      resolution: '1125 x 2436',
    },
    {
      model: 'iPhone 12 Mini',
      resolution: '1080 x 2340',
    },
    {
      model: 'iPhone 12',
      resolution: '1170 x 2532',
    },
    {
      model: 'iPhone 12 Pro',
      resolution: '1170 x 2532',
    },
    {
      model: 'iPhone 12 Pro Max',
      resolution: '1284 x 2778',
    },
    {
      model: 'iPhone 13 Mini',
      resolution: '1080 x 2340',
    },
    {
      model: 'iPhone 13',
      resolution: '1170 x 2532',
    },
    {
      model: 'iPhone 13 Pro',
      resolution: '1170 x 2532',
    },
    {
      model: 'iPhone 13 Pro Max',
      resolution: '1284 x 2778',
    },
    {
      model: 'iPhone SE (3rd generation)',
      resolution: '750 x 1334',
    },
    // Add further models here.
  ],
  Samsung: [
    {
      model: 'Galaxy S',
      resolution: '480 x 800',
    },
    {
      model: 'Galaxy S2',
      resolution: '480 x 800',
    },
    {
      model: 'Galaxy S3',
      resolution: '720 x 1280',
    },
    {
      model: 'Galaxy S4',
      resolution: '1080 x 1920',
    },
    {
      model: 'Galaxy S5',
      resolution: '1080 x 1920',
    },
    {
      model: 'Galaxy S6',
      resolution: '1440 x 2560',
    },
    {
      model: 'Galaxy S7',
      resolution: '1440 x 2560',
    },
    {
      model: 'Galaxy S8',
      resolution: '1440 x 2960',
    },
    {
      model: 'Galaxy S9',
      resolution: '1440 x 2960',
    },
    {
      model: 'Galaxy S10',
      resolution: '1440 x 3040',
    },
    {
      model: 'Galaxy S20',
      resolution: '1440 x 3200',
    },
    {
      model: 'Galaxy S21',
      resolution: '1080 x 2400',
    },
    {
      model: 'Galaxy S22',
      resolution: '1080 x 2340',
    },
    {
      model: 'Galaxy Note 10',
      resolution: '1080 x 2280',
    },
    {
      model: 'Galaxy Note 20',
      resolution: '1080 x 2400',
    },
    {
      model: 'Galaxy Z Fold2',
      resolution: '1768 x 2208 (Main Display)',
    },
    {
      model: 'Galaxy Z Fold3',
      resolution: '1768 x 2208 (Main Display)',
    },
    {
      model: 'Galaxy Z Flip',
      resolution: '1080 x 2636',
    },
    // Additional models can be added here.
  ],
  Google: [
    {
      model: 'Pixel',
      resolution: '1080 x 1920',
    },
    {
      model: 'Pixel 2',
      resolution: '1080 x 1920',
    },
    {
      model: 'Pixel 3',
      resolution: '1080 x 2160',
    },
    {
      model: 'Pixel 3a',
      resolution: '1080 x 2220',
    },
    {
      model: 'Pixel 4',
      resolution: '1080 x 2280',
    },
    {
      model: 'Pixel 4a',
      resolution: '1080 x 2340',
    },
    {
      model: 'Pixel 5',
      resolution: '1080 x 2340',
    },
    {
      model: 'Pixel 6',
      resolution: '1080 x 2400',
    },
    {
      model: 'Pixel 6 Pro',
      resolution: '1440 x 3120',
    },
    {
      model: 'Pixel 7',
      resolution: '1080 x 2400',
    },
    {
      model: 'Pixel 7 Pro',
      resolution: '1440 x 3120',
    },
    // Add further models here as they are released.
  ],
  HTC: [
    {
      model: 'HTC One M7',
      resolution: '1080 x 1920',
    },
    {
      model: 'HTC One M8',
      resolution: '1080 x 1920',
    },
    {
      model: 'HTC One M9',
      resolution: '1080 x 1920',
    },
    {
      model: 'HTC 10',
      resolution: '1440 x 2560',
    },
    {
      model: 'HTC U11',
      resolution: '1440 x 2560',
    },
    {
      model: 'HTC U12+',
      resolution: '1440 x 2880',
    },
    {
      model: 'HTC U Ultra',
      resolution: '1440 x 2560',
    },
    {
      model: 'HTC Exodus 1',
      resolution: '1440 x 2880',
    },
    // Additional models can be added as needed.
  ],
  OnePlus: [
    {
      model: 'OnePlus 5',
      resolution: '1080 x 1920',
    },
    {
      model: 'OnePlus 6',
      resolution: '1080 x 2280',
    },
    {
      model: 'OnePlus 7',
      resolution: '1080 x 2340',
    },
    {
      model: 'OnePlus 7T',
      resolution: '1080 x 2400',
    },
    {
      model: 'OnePlus 8',
      resolution: '1080 x 2400',
    },
    {
      model: 'OnePlus 8 Pro',
      resolution: '1440 x 3168',
    },
    {
      model: 'OnePlus 9',
      resolution: '1080 x 2400',
    },
    {
      model: 'OnePlus 9 Pro',
      resolution: '1440 x 3216',
    },
  ],
  Sony: [
    {
      model: 'Sony Xperia XZ',
      resolution: '1080 x 1920',
    },
    {
      model: 'Sony Xperia XZ1',
      resolution: '1080 x 1920',
    },
    {
      model: 'Sony Xperia XZ2',
      resolution: '1080 x 2160',
    },
    {
      model: 'Sony Xperia XZ3',
      resolution: '1440 x 2880',
    },
    {
      model: 'Sony Xperia 1',
      resolution: '1644 x 3840',
    },
    {
      model: 'Sony Xperia 1 II',
      resolution: '1644 x 3840',
    },
    {
      model: 'Sony Xperia 1 III',
      resolution: '1644 x 3840',
    },
    {
      model: 'Sony Xperia 5 II',
      resolution: '1080 x 2520',
    },
  ],
  LG: [
    {
      model: 'LG G4',
      resolution: '1440 x 2560',
    },
    {
      model: 'LG G5',
      resolution: '1440 x 2560',
    },
    {
      model: 'LG G6',
      resolution: '1440 x 2880',
    },
    {
      model: 'LG G7 ThinQ',
      resolution: '1440 x 3120',
    },
    {
      model: 'LG V30',
      resolution: '1440 x 2880',
    },
    {
      model: 'LG V40 ThinQ',
      resolution: '1440 x 3120',
    },
    {
      model: 'LG V50 ThinQ 5G',
      resolution: '1440 x 3120',
    },
    {
      model: 'LG V60 ThinQ 5G',
      resolution: '1080 x 2460',
    },
  ],
};
