import defaultTemplate from './templates/default';
import roomSubmitted from './templates/roomSubmitted';
import roomSubmittedError from './templates/roomSubmittedError';
import roomTypeSelection from './templates/roomTypeSelection';
import validLink from './templates/validLink';
import axios from 'axios';

import { IS_TEST_ENV } from 'src/utils/utils';

type Template = {
  data: any;
  type: 'Success' | 'Info' | 'Error';
  template: string;
};

const SLACK_URL = process.env.REACT_APP_SLACK_URL || '';

const colorByType: { [index: string]: string } = {
  info: '#0096FF',
  error: '#FF4057',
  success: '#53B763',
};

const common: { [index: string]: (data: any) => any } = {
  validLink: () => validLink(),
  roomSubmitted: (data) => roomSubmitted(data),
  roomTypeSelection: (data) => roomTypeSelection(data),
  roomSubmittedError: (data) => roomSubmittedError(data),
};

export const template = ({ data, type, template }: Template) => {
  const messageType = type.toLowerCase();
  const selectedTemplate = template ? common[template] : defaultTemplate;
  return {
    attachments: [
      {
        color: colorByType[messageType],
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: data.header || 'NA',
              emoji: true,
            },
          },
          ...selectedTemplate(data),
        ],
      },
    ],
  };
};

export const postMessage = async (slackTemplate: any) => {
  if (!IS_TEST_ENV) {
    await axios.post(SLACK_URL, JSON.stringify(slackTemplate), {
      withCredentials: false,
      transformRequest: [
        (data, headers: any) => {
          delete headers.post['Content-Type'];
          return data;
        },
      ],
    });
  }
};
