import BrowserAssistedCapture from './BrowserAssistedCapture/BrowserAssistedCapture';
import SmallRoomCapture from './SmallRoom/SmallRoomCapture';
import { useImageStore } from 'src/stores/image';
import { ErrorBoundary } from 'react-error-boundary';
import * as log from 'src/utils/logger';
import { useEffect } from 'react';
import { useReadLocalStorage } from 'usehooks-ts';
import { useFeatureStore } from 'src/stores/featureStore';

interface FeedbackProps {
  imageNumber: number;
  imageData: string;
  onComplete: (id: number) => void;
}

const FeedBack = ({ imageNumber, imageData, onComplete }: FeedbackProps) => {
  const feedbackDisabled = useReadLocalStorage<string>('disable_feedback');
  const enableAIAssistance = useFeatureStore((state) => state.aiAssist);

  // Use the small room capture until the model is loaded.
  const modelLoaded = useImageStore((state) => state.modelLoaded);

  useEffect(() => {
    if (!modelLoaded && enableAIAssistance && feedbackDisabled !== 'true') {
      log.info({
        event: 'Model not loaded yet!',
        data: { imageNumber, message: 'using small room capture as fallback!' },
      });
    }
  }, [modelLoaded, enableAIAssistance, feedbackDisabled, imageNumber]);

  if (modelLoaded && enableAIAssistance && feedbackDisabled !== 'true') {
    return (
      <ErrorBoundary
        fallback={<SmallRoomCapture imageNumber={imageNumber} imageData={imageData} onComplete={() => onComplete(imageNumber)} />}
        onError={(error, info) => {
          log.error({
            event: 'Rendering error',
            data: { imageNumber, error, info },
          });
        }}>
        <BrowserAssistedCapture imageNumber={imageNumber} imageData={imageData} onComplete={() => onComplete(imageNumber)} />
      </ErrorBoundary>
    );
  }

  return <SmallRoomCapture imageNumber={imageNumber} imageData={imageData} onComplete={() => onComplete(imageNumber)} />;
};

export default FeedBack;
