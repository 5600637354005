import React, { useEffect, useState } from 'react';
import BrandingHeader from 'src/components/BrandingHeader/BrandingHeader';
import ContentView from 'src/components/Content/Content';
import Text from 'src/components/Text/Text';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImageSlider from './slider';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Capture from './capture';
import { useNavigate, useParams } from 'react-router-dom';
import { getClaimOverview } from 'src/REST/capture';
import { useEventStore } from 'src/stores/eventStore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useValidateLink from 'src/hooks/useValidateLink/useValidateLink';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { v4 as uuid } from 'uuid';
import { getImageUrl } from './utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Recapture() {
  const { linkId } = useParams();
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [json, setJson] = useState<any[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const setLoading = useEventStore((state) => state.setLoading);
  const setError = useEventStore((state) => state.setError);
  const navigate = useNavigate();
  const claimInfo = useClaimInfoStore();
  useValidateLink(linkId || 'not-found');

  useEffect(() => {
    if (!linkId) return;
    setLoading(true);
    if (!claimInfo.claimId) return;
    getClaimOverview(claimInfo.claimId)
      .then((res) => {
        const roomJson = res.data.claims
          .map((claim: any) => {
            const rooms = claim.rooms
              .filter((room: any) => {
                return room.status !== 'Initiated';
              })
              .map((room: any) => {
                const imageArray = Object.values({ ...room.imageQA?.[0]?.image_order } || {}) as string[];
                const missingWalls = room.imageQA?.[0]?.missing_walls || [];
                const imageIds = Object.values(
                  imageArray.reduce((acc, curr: any) => {
                    return { ...acc, ...curr };
                  }, {})
                ) as string[];
                const missingGraphics: Record<string, { left: string | undefined; right: string | undefined }> = {};
                missingWalls.forEach((wall: any) => {
                  const idx = imageIds.indexOf(wall.leftImageId);
                  if (idx > -1) {
                    const id = uuid();
                    imageIds.splice(idx + 1, 0, id);
                    const leftUrl = getImageUrl(wall.leftImageId, room.imageUrls);
                    const rightUrl = wall?.rightImageId ? getImageUrl(wall.rightImageId, room?.imageUrls) : room?.imageUrls[0];
                    missingGraphics[id] = { left: leftUrl, right: rightUrl };
                  }
                });
                return {
                  roomId: room.roomId,
                  roomName: room.roomName,
                  roomType: room.roomType,
                  status: room.status,
                  imageQaRejectionCount: room.imageQaRejectionCount,
                  complete: room?.roomId === selected,
                  photos:
                    imageIds.length > 0
                      ? imageIds.map((imageIdFile: string) => {
                          const imageId = imageIdFile.split('.')[0];
                          const item = room.imageQA?.[0]?.errors?.find((error: any) => error.imageid === imageId);
                          if (missingGraphics[imageId]) {
                            return {
                              leftImage: missingGraphics[imageId].left,
                              rightImage: missingGraphics[imageId].right,
                              type: 'missing',
                              issues: ['Missing the section of the room between these two walls.'],
                              graphics: null,
                            };
                          }
                          return {
                            imageUrl: getImageUrl(imageIdFile, room.imageUrls),
                            type: 'regular',
                            issues: item?.label || [],
                            graphics: item?.drawings || null,
                          };
                        })
                      : room.imageUrls.map((url: string) => {
                          return {
                            imageUrl: url,
                            type: 'regular',
                            issues: [],
                            graphics: null,
                          };
                        }),
                };
              });
            return rooms;
          })
          .flat();
        setJson(roomJson);
        setLoading(false);
        setSelected(null);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Unable to get Claim Overview. Please try again later.', true);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [claimInfo.claimId, setError, setLoading, refresh]);

  const handleSubmit = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  const handleSelect = (room: any, retakeCount: number) => {
    if (retakeCount > 0) setSelected(room.roomId);
    else navigate(`/capture/${linkId}/${room.roomId}/recapture`);
  };

  const roomData = json.filter((e) => {
    if (value === 1) return e.complete || e.status === 'Processed' || e.status === 'Processing';
    return !e.complete && e.status === 'Not Processable';
  });

  return (
    <ContentView className="gap-2 min-h-screen -mt-14 pt-2 landscape:pb-24" variant="secondary">
      <>
        <div className="flex flex-col gap-4 w-full px-4">
          <BrandingHeader logo="/images/damage/Hosta Logo.png" logoVerified hideHostaBranding={false} extraClassName="pb-0" />
          <Text variant="title">Room Captures</Text>
          <Tabs value={value} onChange={(_, value) => setValue(value)}>
            <Tab label="Needs Attention" />
            <Tab label="Submitted" />
          </Tabs>
          {roomData.map((e) => {
            const retakeCount = e.photos?.filter((e: any) => e.issues?.length > 0 || Boolean(e.graphics))?.length;
            return (
              <Card key={e.roomId}>
                <CardContent>
                  <Text variant="subtitle" align="left" extraClassname="text-blue-900 font-semibold capitalize">
                    {e.roomName}
                  </Text>
                  {(e.complete || e.status === 'Processed' || e.status === 'Processing') && value === 1 && (
                    <>
                      <Text variant="paragraph" align="left">
                        {e.complete ? 'New Photos Uploaded' : 'Photos Submitted for Review'}
                      </Text>
                      <div className="my-2">
                        {e.status !== 'Processed' && <small className="bg-yellow-500 rounded-full p-2 font-lg">Pending Approval</small>}
                        {e.status === 'Processed' && <small className="bg-green-500 rounded-full p-2 font-lg">Approved</small>}
                      </div>
                    </>
                  )}
                  {!e.complete && value === 0 && e.status === 'Not Processable' && (
                    <>
                      <Text variant="paragraph" align="left">
                        {retakeCount > 0 ? `${retakeCount} photo(s) need to be retaken.` : 'Retake all photos.'}
                      </Text>
                      {retakeCount > 0 && (
                        <div className="w-full">
                          <ImageSlider onChange={() => {}} images={e.photos?.filter((e: any) => e.issues?.length > 0 || Boolean(e.graphics))} />
                        </div>
                      )}
                    </>
                  )}
                </CardContent>
                <CardActions>
                  {value === 0 && (
                    <div className="px-2 w-full">
                      <Button onClick={() => handleSelect(e, retakeCount)} className="!font-bold" fullWidth variant="contained">
                        {retakeCount > 0 ? `See Feedback` : `Retake Photos`}
                      </Button>
                    </div>
                  )}
                </CardActions>
              </Card>
            );
          })}
          {roomData.length === 0 && (
            <div className="flex flex-col items-center text-slate-400 p-4 gap-4">
              <CheckCircleIcon color="inherit" fontSize="large" />
              {value === 1 ? <Text variant="paragraph">No rooms submitted</Text> : <Text variant="paragraph">No action needed</Text>}
            </div>
          )}
        </div>
        <Dialog fullScreen open={Boolean(selected)} onClose={() => setSelected(null)} TransitionComponent={Transition}>
          <Capture handleSubmit={handleSubmit} handleClose={() => setSelected(null)} imageData={json.find((e) => e.roomId === selected)} />
        </Dialog>
      </>
    </ContentView>
  );
}
