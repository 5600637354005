import * as Sentry from '@sentry/react';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addContactInfo, getImageLink, submitRoom } from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useLocalStorage } from 'usehooks-ts';
import { useFeatureStore } from 'src/stores/featureStore';
import { useUploadQueueStore } from 'src/stores/uploadQueue';

import { dexieDb, imagesUploaded, IPicture, validateIndexedDBFields } from 'src/utils/indexedDb';

import Loading from 'src/components/Loading/Loading';
import PhotosLoading from 'src/components/PhotosLoading/PhotosLoading';
import Modal from 'src/components/Modal/Modal';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';
import { uploadRoomImage } from 'src/utils/uploadImage';
import { base64ToFile } from 'src/utils/utils';

import flagsmith from 'flagsmith';

const deletePhotos = (pictures: IPicture[]) => {
  const ids = pictures?.map((e) => e.id);
  if (ids?.length) {
    dexieDb.pictures.bulkDelete(ids);
  }
};

const ConfirmPhotosPage: React.FC<PropsWithChildren> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const claimInfo = useClaimInfoStore();
  const features = useFeatureStore();
  const uploadQueue = useUploadQueueStore((state) => state.eventQueue);
  const bgUplaoderInProgress = useRef(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [badDBError, setBadDBError] = useState<string | null>();
  const [, setCachedRoomId] = useLocalStorage<string | null>('cachedRoomId', null);
  const recaptureInfo = useClaimInfoStore((state) => state.recaptureInfo);
  const layoutCapture = useFeatureStore((state) => state.layoutCapture);
  const recaptureKey = recaptureInfo?.isRecapture ? 'RECAPTURE_' + recaptureInfo?.rejectionCount : undefined;

  const pictures = useLiveQuery(
    async () =>
      dexieDb.pictures
        .where('roomId')
        .equals(params.roomId || '')
        .toArray(),
    [params.roomId]
  );

  useEffect(() => {
    if (pictures && !load && !error) {
      const imgsUploaded = imagesUploaded(pictures);
      const hasDamage = pictures.filter((e) => e.imgType === 'overviewImage').length !== pictures.length;

      if (hasDamage && claimInfo?.allowDamage && navigator.onLine && !imgsUploaded) {
        linkDamages();
      } else {
        (async () => {
          await flagsmith.getFlags();
          const newSubmitLoader = flagsmith.hasFeature('new_submit_loader');
          if (imgsUploaded && newSubmitLoader) {
            if (recaptureInfo?.isRecapture || !hasDamage) {
              onNextButtonClick(false);
            } else {
              setLoad(true);
            }
          } else if (imgsUploaded && !newSubmitLoader) {
            if (recaptureInfo?.isRecapture || !hasDamage) {
              onNextButtonClick(false);
            } else {
              deletePhotos(pictures);
              navigate(`/${params.captureType}/${params.claimId}/${params.roomId}/room-submitted`);
            }
          } else {
            onNextButtonClick(false);
          }
        })();
      }
    }
    // eslint-disable-next-line
  }, [load, pictures, error, navigator.onLine]);

  useEffect(() => {
    if (load && !uploadQueue.length && bgUplaoderInProgress.current) {
      bgUplaoderInProgress.current = false;
      onNextButtonClick(false);
    }
    // eslint-disable-next-line
  }, [uploadQueue, load]);

  const linkDamages = async () => {
    const damageImages = pictures?.filter((e) => e.imgType !== 'overviewImage');
    const overviewImages = pictures?.filter((e) => e.imgType === 'overviewImage').map((e) => e.id);
    const updatedDamageImages = damageImages?.map((e) => {
      return {
        ...e,
        moreImages: overviewImages?.filter((e) => Boolean(e) || e === 0),
      };
    });
    if (updatedDamageImages) {
      await dexieDb.pictures.bulkPut(updatedDamageImages);
      onNextButtonClick(false);
    }
  };

  const cleanDamageImages = async () => {
    const pics = await dexieDb.pictures
      .where('roomId')
      .equals(params.roomId || '')
      .toArray();
    const group = pics.reduce((acc: any, item) => {
      if (item.damageId === undefined) return acc;
      if (!acc[item.damageId]) {
        acc[item.damageId] = [];
      }
      acc[item.damageId].push(item);
      return acc;
    }, {});
    let damageIds = Object.keys(group).filter((e) => {
      return group[e].length !== 2;
    });
    await dexieDb.pictures.bulkDelete(pics.filter((e) => e.damageId && damageIds.includes(e.damageId)).map((e) => e.id));
  };

  const getBase64StringFromDataURL = (dataURL: string) => dataURL.replace('data:', '').replace(/^.+,/, '');

  const onNextButtonClick = (autoUpload: boolean) => {
    let start = performance.now();
    const failed: any[] = [];
    const uploaded: any[] = [];
    const results: any[] = [];

    if (!navigator.onLine) {
      setCachedRoomId(params.roomId || null);
      setError('No Internet! Please try again when you have internet connection');
      return;
    }

    // If there are no photos to process redirect to the next page.
    if (!pictures?.length) {
      navigate(`/${params.captureType}/${params.claimId}/${params.roomId}/room-submitted`);
      return;
    }

    setLoad(true);

    if (uploadQueue.length && !bgUplaoderInProgress.current) {
      bgUplaoderInProgress.current = true;
      return;
    }

    (async () => {
      await cleanDamageImages();
      const pics = await dexieDb.pictures
        .where('roomId')
        .equals(params.roomId || '')
        .toArray();
      const wallPhotos = pics.filter((e) => e.imgType === 'overviewImage');
      const damagesPhotos = pics.filter((e) => e.imgType !== 'overviewImage');

      const validWallPhotos = validateIndexedDBFields(wallPhotos);

      if (!validWallPhotos) {
        setLoad(false);
        setBadDBError('An issue occurred during the capture. Please restart the capture process.');
        return;
      }

      for (const index in wallPhotos) {
        const img = wallPhotos[index];
        if (img.uploaded) {
          uploaded.push({ ...img, id: img.uploadId });
          results.push({ id: img.uploadId, key: img?.awsKey });
          if (img.extraImages?.length) {
            uploaded.push({
              ...img,
              id: (img.uploadId || 0) + 1,
              key: `extra-${img?.awsKey}`,
            });
          }
        } else {
          try {
            const res = await uploadRoomImage({
              imgId: img.id,
              claimId: params.claimId || '',
              roomId: params.roomId || '',
              recaptureKey,
              imageField: layoutCapture ? 'rejectedImages' : 'overviewImages',
            });

            results.push(res.image);
            uploaded.push({
              id: img?.uploadId?.toString(),
              ...(res.image as {}),
            });

            if (res?.extraImage) {
              uploaded.push({
                id: ((img.uploadId || 0) + 1).toString(),
                key: `extra-${img?.awsKey}`,
                ...(res.extraImage as {}),
              });
            }
          } catch (e) {
            failed.push(img);
            Sentry.captureException(e);
          }
        }
      }

      for (const index in damagesPhotos) {
        const img = damagesPhotos[index];
        const file = base64ToFile(getBase64StringFromDataURL(img.content));
        const imageField = claimInfo?.allowDamage ? 'damageImages' : 'additionalImages';
        const imageType = claimInfo?.allowDamage ? img.imgType : img.imgType?.replace('damage', 'additional');
        const imageSet = claimInfo?.allowDamage ? 'damageId' : 'imageSetId';

        try {
          const imageId = (img.id + 1).toString();
          const res = await getImageLink(
            params.claimId || '',
            params.roomId || '',
            file,
            'jpeg',
            imageId,
            imageField,
            imageType,
            !autoUpload ? img.moreImages?.map((e) => results[e - 1]?.key).filter((e) => Boolean(e) || e === 0) : [results[0]?.key],
            { [imageSet]: img.damageId }
          );
          uploaded.push({ id: img.id, ...(res as {}) });
          await dexieDb.pictures.update(img.id, {
            uploaded: true,
          });
        } catch (e) {
          failed.push(img);
          Sentry.captureException(e);
        }
      }

      if (!failed.length) {
        try {
          const uploadsTracked = recaptureInfo?.isRecapture ? false : undefined;
          // Do not submit the room if is layout capture POC
          if (!layoutCapture) {
            await submitRoom(params.claimId || '', params.roomId || '', 'Living', uploaded.length, uploadsTracked);
          }
          if (claimInfo?.contactInfo && claimInfo?.contactInfo !== '' && params.claimId) await addContactInfo(params.claimId, claimInfo.contactInfo);
          const elapsedTime = Math.floor(((performance.now() - start) / 1000) % 60);
          log.success({
            event: eventNames.SUBMIT_ROOM_SUCCESS,
            data: {
              uploaded,
              header: 'Room Submitted :rocket:',
              roomId: params.roomId,
              setName: claimInfo?.allowDamage ? 'Damage id' : 'Image Set Id',
              totalTimeSeconds: elapsedTime,
              template: 'roomSubmitted',
            },
          });
          localStorage.removeItem('cachedRoomId');
          if (!features.newSubmitLoader) {
            deletePhotos(pictures);
            navigate(`/${params.captureType}/${params.claimId}/${params.roomId}/room-submitted`);
          }
          if (flagsmith.hasFeature('notify_parent_app')) {
            sendMessagesToParentApp(uploaded);
          }
        } catch (e) {
          setError('Unable to Submit Image(s)');
          setLoad(false);
          Sentry.captureException(e);
        }
      } else {
        setError(`Unable to upload image(s) ${failed.map((img) => img.id).join(',')}, please try submitting again!`);
        setLoad(false);
        log.error({
          event: eventNames.SUBMIT_ROOM_FAIL,
          ignoreSlack: false,
          data: {
            failed,
            uploaded,
            header: 'Failed to upload images',
            roomId: params.roomId,
            template: 'roomSubmittedError',
            error: 'Failed to upload images',
          },
        });
      }
    })();
  };

  const sendMessagesToParentApp = (upload: any[]) => {
    const message = JSON.stringify({ success: 'Room Submitted', upload });
    if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.iosListener) {
      (window as any).webkit.messageHandlers.iosListener.postMessage(message);
    }
    if ((window as any).Android && (window as any).Android?.logData) {
      (window as any).Android.logData(message);
    }
    if (window.parent) {
      window.parent.postMessage(message, '*');
    }
  };

  if (!pictures) return <></>;

  const handleOpen = () => {
    const hasDamage = pictures.filter((e) => e.imgType === 'overviewImage').length !== pictures.length;
    if (hasDamage && claimInfo.allowDamage) {
      linkDamages();
    } else {
      onNextButtonClick(false);
    }
  };

  const handleCloseErrorModal = () => {
    handleOpen();
    setError(null);
  };

  const handleBadDBErrorModal = () => {
    setBadDBError(null);
    navigate(`/${params.captureType}/${params.claimId}/room-select`);
  };

  return (
    <>
      {features.newSubmitLoader ? <PhotosLoading open={load} roomId={params.roomId} /> : null}
      {!badDBError && !error && !features.newSubmitLoader ? <Loading open={load} /> : null}
      {error && <Modal noCloseHandlers={!navigator.onLine} handleClose={handleCloseErrorModal} handleDone={handleCloseErrorModal} buttonText="Got it" title="Something went wrong!" text={error} />}
      {badDBError && <Modal noCloseHandlers handleClose={handleBadDBErrorModal} handleDone={handleBadDBErrorModal} buttonText="Restart Capture" title="Something went wrong!" text={badDBError} />}
    </>
  );
};

export default ConfirmPhotosPage;
