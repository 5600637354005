import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import classnames from 'classnames';
import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';

type Props = {
  image?: string | null;
  blank?: boolean;
  type: 'floor' | 'ceiling' | 'both';
  zoom?: number;
};
type Timeout = ReturnType<typeof setTimeout>;

const Camera = forwardRef(({ image, blank, type, zoom = 1 }: Props, ref: React.Ref<Webcam>) => {
  const constrainsTimeout = useRef<Timeout | null>(null);
  const [cameraLoaded, setCameraLoaded] = useState(false);

  useEffect(() => {
    return () => {
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref?.current?.video?.srcObject?.getVideoTracks?.()?.[0]?.stop?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCameraLoaded(false);
    const applyConstraints = async () => {
      // @ts-ignore
      if (ref?.current?.video?.readyState === 4) {
        try {
          // @ts-ignore
          const track = ref?.current?.video?.srcObject?.getVideoTracks?.()?.[0];
          await track?.applyConstraints?.({
            zoom: zoom,
            ...(type !== 'both' && { width: 720, height: 1280 }),
            ...(type === 'both' && { width: 1280, height: 720 }),
          });
          log.event({ event: eventNames.VIDEO_CONSTRAINS_APPLIED, data: { zoom } });
        } catch (error) {
          log.event({ event: eventNames.VIDEO_CONSTRAINS_ERROR, data: { error } });
        } finally {
          setTimeout(() => setCameraLoaded(true), 1100);
        }
      } else {
        constrainsTimeout.current = setTimeout(applyConstraints, 100);
      }
    };

    applyConstraints();

    return () => {
      setCameraLoaded(false);
      constrainsTimeout.current && clearTimeout(constrainsTimeout.current);
    };
  }, [ref, zoom, type]);

  return (
    <div
      className={classnames('w-full overflow-hidden relative', {
        'h-[100dvh]': type === 'both',
        'h-[50dvh]': type !== 'both',
      })}>
      {!image ? (
        <Webcam
          ref={ref}
          audio={false}
          forceScreenshotSourceSize={true}
          videoConstraints={{ facingMode: { exact: 'environment' } }}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          className={classnames({ 'blur-lg opacity-30': blank || !cameraLoaded })}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      ) : null}
      <div
        className={classnames('w-full h-full absolute top-0 left-0 bg-black transition-opacity duration-300 ease-out', {
          'opacity-0': cameraLoaded,
        })}
      />
      {image ? <img alt="test" src={image} className="w-full h-[50dvh] object-cover" /> : null}
      {type === 'ceiling' ? (
        <div className="w-full absolute top-0 left-0 p-2 flex flex-col items-end">
          <p className="text-black text-lg mb-3 mt-1 bg-white/50 p-1">Ceiling</p>
          <div className="border-b-2 border-dashed border-white/50 w-full h-[1px]" />
          <p className="text-black text-lg mt-3 mb-1 bg-white/50 p-1">Wall</p>
        </div>
      ) : null}
      {type === 'floor' && !blank ? (
        <div className="w-full absolute bottom-0 left-0 p-2 flex flex-col items-end">
          <p className="text-black text-lg mb-3 mt-1 bg-white/50 p-1">Wall</p>
          <div className="border-b-2 border-dashed border-white/50 w-full h-[1px]" />
          <p className="text-black text-lg mt-3 mb-8 bg-white/50 p-1">Floor</p>
        </div>
      ) : null}
      {type === 'both' ? (
        <div className="flex flex-col justify-between w-full h-full absolute bottom-0 left-0 p-2">
          <div className="w-full flex flex-col items-end">
            <p className="text-black text-lg mb-3 mt-1 bg-white/50 p-1 ceiling-frame">Ceiling</p>
            <div className="border-b-2 border-dashed border-white/50 w-full h-[1px]" />
          </div>

          <div className="w-full flex flex-col items-end">
            <p className="text-black text-lg bg-white/50 p-2 mr-5">Wall</p>
          </div>

          <div className="w-full flex flex-col items-end">
            <div className="border-b-2 border-dashed border-white/50 w-full h-[1px]" />
            <p className="text-black text-lg mt-3 mb-8 bg-white/50 p-1 floor-frame">Floor</p>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default Camera;
