type Props = {
  className: string;
};

const CloseUp = ({ className }: Props) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_24_9161)">
      <path
        d="M74.0391 71.1228C76.5252 71.3532 79.0344 71.5145 80.9451 71.5376C83.9146 71.5837 85.48 71.3532 86.4238 70.9384C87.3906 70.5236 87.759 69.9475 87.9892 69.5788C88.2194 69.2331 88.3114 69.0948 88.6798 68.8644C89.0711 68.6339 89.7387 68.3343 90.9817 67.7352C91.1659 67.643 91.3501 67.5508 91.5342 67.4586M91.5342 54.6919C90.4523 54.6458 89.3704 54.6227 88.3575 54.5766C84.4671 54.4384 81.4055 54.254 78.9193 54.231C76.4562 54.231 74.5685 54.3923 73.4636 54.761C72.3356 55.1297 71.9903 55.6828 71.9673 56.1667C71.9673 56.6507 72.2896 57.0885 71.8752 57.4803C71.4609 57.8951 70.2868 58.2638 68.3992 58.4481C66.5116 58.6325 63.9333 58.6095 62.0918 58.8169C60.2271 59.0243 59.1222 59.4852 58.2244 60.1535C57.3496 60.8218 56.7051 61.7205 56.3828 62.6193"
        stroke="#2F7FF1"
        strokeWidth="1.25533"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.1406 104.701C55.1406 104.701 21.4256 111.808 23.4828 98.2615C23.4828 67.2557 44.85 59.1316 44.85 59.1316V67.7355L36.5069 72.6094C32.6668 74.8519 30.1295 78.4674 29.2381 82.5176C28.6666 85.172 28.7809 88.0094 29.741 90.7782C31.5924 96.1785 35.4097 99.8855 39.8213 99.8855C43.4328 99.8855 46.6329 97.4142 48.6901 93.547C49.2844 93.753 49.9245 93.8674 50.5873 93.8674H68.3022C68.3022 93.8674 67.7353 103.809 55.1406 104.701Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9135 51.9011C94.3592 49.201 90.1533 48.6975 86.5189 50.8027C82.8845 52.9079 81.2158 56.798 82.7702 59.4981C84.3245 62.1983 88.5304 62.7017 92.1648 60.5965C95.7992 58.4913 97.4678 54.6012 95.9135 51.9011Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9135 64.3011C94.3592 61.6009 90.1533 61.0975 86.5189 63.2027C82.8845 65.3079 81.2158 69.1979 82.7702 71.8981C84.3245 74.5982 88.5304 75.1017 92.1648 72.9965C95.7992 70.8913 97.4678 67.0012 95.9135 64.3011Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.3525 76.9337C91.7981 74.2335 87.5923 73.7301 83.9578 75.8353C80.3234 77.9405 78.6548 81.8306 80.2091 84.5307C81.7635 87.2309 85.9693 87.7343 89.6038 85.6291C93.2382 83.5239 94.9068 79.6338 93.3525 76.9337Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M83.5916 5.3331H50.6076C47.4532 5.3331 44.8474 7.91883 44.8474 11.0995V88.1224C44.8474 91.2802 47.4304 93.8888 50.6076 93.8888H83.5916C86.746 93.8888 89.3518 91.3031 89.3518 88.1224V11.0995C89.3518 7.94172 86.7689 5.3331 83.5916 5.3331Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="3.09028"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M68.3012 93.8659C68.3012 93.8659 67.3041 104.641 54.7094 105.533"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M44.85 59.1316C44.85 59.1316 22.421 66.8928 23.4131 101.615"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9743 81.465L55.3173 78.9708H63.2262C73.2151 78.9708 76.3466 65.3328 69.4207 65.3328H53.6487C50.4486 65.3328 47.6828 66.0879 44.9169 67.6897L36.5052 72.6094C30.1507 76.3164 27.362 83.799 29.7393 90.7782C31.5908 96.1785 35.408 99.8855 39.8196 99.8855C46.037 99.8855 51.0657 92.5173 51.0657 83.4558C51.0657 82.7922 51.0429 82.1286 50.9743 81.4879V81.465Z"
        fill="white"
      />
      <path
        d="M43.9473 97.6703C48.336 95.4278 51.0429 90.6194 51.0429 83.4571C51.0429 82.7935 51.02 82.1299 50.9514 81.4892L55.2944 78.995H63.2033C73.1922 78.995 76.3238 65.357 69.3978 65.357H53.6258C50.4257 65.357 47.6599 66.1121 44.8941 67.7139L37.991 72.169C34.1509 74.4115 31.6136 78.0269 30.7222 82.0772"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M78.6786 5.3331H55.4778V7.91883C55.4778 8.99432 56.3692 9.88674 57.4436 9.88674H76.7128C77.7871 9.88674 78.6786 8.99432 78.6786 7.91883V5.3331Z"
        fill="#2F7FF1"
        className={`${className} fill-icons-color`}
      />
      <path
        d="M60.1184 7.89487C60.5602 7.89487 60.9184 7.5363 60.9184 7.09398C60.9184 6.65166 60.5602 6.29309 60.1184 6.29309C59.6765 6.29309 59.3184 6.65166 59.3184 7.09398C59.3184 7.5363 59.6765 7.89487 60.1184 7.89487Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7292 6.26886H70.4723C70.9066 6.26886 71.2723 6.63498 71.2723 7.06975C71.2723 7.50452 70.9066 7.87064 70.4723 7.87064H63.7292C63.2949 7.87064 62.9292 7.50452 62.9292 7.06975C62.9292 6.63498 63.2949 6.26886 63.7292 6.26886Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0884 33.796H82.0375C86.4719 33.796 86.4719 41.0726 82.0375 41.0726H67.0884V33.796V33.796Z"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M44.8474 55.3993H62.5395C65.0538 55.3993 67.0882 53.3628 67.0882 50.8457V9.8858"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0884 24.2081H82.0375C86.4719 24.2081 86.4719 31.4848 82.0375 31.4848H67.0884V24.2081V24.2081Z"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M60.1402 9.8858V24.6908M60.1402 32.6311V43.3173C60.1402 49.2439 50.4255 49.2439 50.4255 43.3173V42.3562M50.4255 38.9467V20.2974M50.4255 14.5539V12.2198"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.2084 47.8481C80.2084 49.3813 78.974 50.6169 77.4426 50.6169C75.9111 50.6169 74.6768 49.3813 74.6768 47.8481C74.6768 46.315 77.4426 42.608 77.4426 42.608C77.4426 42.608 80.2084 46.315 80.2084 47.8481Z"
        fill="#CBDFFC"
        stroke="#2F7FF1"
        strokeWidth="1.2465"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path d="M77.4888 39.081V36.6784" stroke="#2F7FF1" strokeWidth="1.2465" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" className={className} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.8879 73.7736C74.2904 74.0024 76.3161 74.1626 78.1625 74.1855C81.0322 74.2313 82.5449 74.0024 83.457 73.5906C83.5014 73.5677 83.5459 73.5448 83.5904 73.5219C84.4135 73.11 84.7472 72.5837 84.9697 72.2405C85.1921 71.8972 85.2811 71.76 85.637 71.5311C86.0152 71.3023 86.6603 71.0048 87.8616 70.4099C88.0396 70.3183 88.2175 70.2268 88.3955 70.1353V62.1035V61.7145V57.4583C87.35 57.4126 86.3044 57.3897 85.3256 57.3439C83.9241 57.2981 82.6339 57.2295 81.4326 57.1837C79.4305 57.0922 77.7176 57.0236 76.2271 57.0007C73.8468 57.0007 72.0226 57.1608 70.9548 57.527C69.8648 57.8931 69.5311 58.4423 69.5089 58.9228C69.5089 59.4033 69.8203 59.8381 69.4199 60.2271C69.0195 60.639 67.8849 61.0051 66.0608 61.1882C64.2366 61.3712 61.7451 61.3484 59.9654 61.5543C58.1635 61.7603 57.0957 62.2179 56.2281 62.8815C55.3828 63.5451 54.7599 64.4375 54.4485 65.3299H69.0195C72.9347 65.3299 73.9568 69.9293 71.8879 73.7736Z"
        fill="#CBDFFC"
        stroke="#2F7FF1"
        strokeWidth="1.25113"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <g opacity="0.6">
        <path d="M1.25 49.8071H44.0371" stroke="#2F7FF1" strokeWidth="1.25533" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" className={className} />
        <path
          d="M30.4856 1.25V25.4009M30.4856 31.8995V37.6607C30.4856 43.3297 40.2691 44.2515 40.2691 37.0385V1.25"
          stroke="#2F7FF1"
          strokeWidth="1.25533"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          d="M10.7571 1.25V34.711M10.7571 37.6607C10.7571 43.3297 20.5405 44.2515 20.5405 37.0385V19.5475M20.5405 11.044V1.25"
          stroke="#2F7FF1"
          strokeWidth="1.25533"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          d="M45.8593 56.5483C45.1457 56.5483 46.5038 56.5483 45.8593 56.5483ZM45.8593 56.5483C41.6236 56.5483 39.5518 56.5483 38.1706 56.8479C36.8124 57.1705 36.1449 57.8157 35.7996 58.3458C35.4543 58.8758 35.4313 59.3136 34.9478 59.6132C34.4644 59.9128 33.5436 60.0511 31.9322 60.2815C30.2978 60.512 27.9728 60.7885 26.1542 61.1572C24.3357 61.5259 23.0465 61.9638 22.2408 62.3786C21.4121 62.7934 21.0438 63.1621 20.6064 63.5539C20.169 63.9687 19.6396 64.4296 19.5245 65.1209C19.4094 65.8353 19.6626 66.7802 20.9978 67.7019C22.3329 68.6007 24.727 69.4764 27.4664 70.2138C29.331 70.7208 29.847 70.7601 30.7987 70.9474"
          stroke="#2F7FF1"
          strokeWidth="1.25533"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2835 56.5493C45.5698 56.5493 44.9023 56.5493 44.2577 56.5493C40.0221 56.5493 38.8732 56.612 37.492 56.9116C36.1339 57.2342 35.4443 58.5304 35.099 59.0604C34.7537 59.5905 33.8297 59.3147 33.3463 59.6143C32.8629 59.9139 31.9421 60.0521 30.3307 60.2826C28.6962 60.513 26.3712 60.7896 24.5527 61.1583C22.7341 61.527 21.445 61.9648 20.6393 62.3796C19.8106 62.7944 19.4422 63.1632 19.0049 63.5549C18.5675 63.9697 18.038 64.4306 17.9229 65.122C17.8078 65.8364 18.061 66.7812 19.3962 67.703C20.7314 68.6017 23.1254 69.4774 25.8648 70.2149C27.7294 70.7218 28.4345 70.5157 30.4602 70.9305C36.5835 61.2978 46.3065 58.6464 46.3065 58.6464V56.5263L46.2835 56.5493Z"
          fill="#2F7FF1"
          className={className}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_24_9161">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseUp;
