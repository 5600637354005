// Components
// Hooks
import { useLiveQuery } from 'dexie-react-hooks';
// Types
import React, { ReactElement } from 'react';

// Utils
import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import Text from 'src/components/Text/Text';
import RetakeCamera from 'src/components/icons/common/RetakeCamera';

type Props = {
  id: number;
  label: string;
  showRetake: boolean;
  imageData?: string;
  handleCapture?: (data: string) => void;
  capFirstLetterOnly?: boolean;
};

const PreviewPhoto = ({ id, label, showRetake, imageData, handleCapture, capFirstLetterOnly }: Props): ReactElement => {
  const photo = useLiveQuery(async () => dexieDb.pictures.where({ id }).first());

  const onCapture = async (data: string) => {
    await dexieDb.pictures.update(id, { content: data });
  };

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center">
        <Text testId="preview-photo-label" variant="paragraph" extraClassname="text-[#001D47] capitalize">
          {label}
        </Text>
        {showRetake && (
          <div data-testid="preview-photo-retake-btn" className="flex items-center">
            <div className="mr-2">
              <RetakeCamera className="fill-icons-color" />
            </div>
            <BaseButton
              contained
              className="text-icons-color text-sm"
              onCapture={imageData ? onCapture : handleCapture}
              label="retake photo"
              weight="medium"
              type="capture"
              capFirstLetterOnly={capFirstLetterOnly}
            />
          </div>
        )}
      </div>

      <img data-testid="preview-photo-picture" alt={label} className="w-full rounded-lg" src={imageData ? imageData : photo?.content} />
    </div>
  );
};

export default PreviewPhoto;
