import { analytics } from 'src/texts/texts';
import { useNavigate } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Text from 'src/components/Text/Text';
import CloseUp from 'src/components/icons/damage/CloseUp';
import Context from 'src/components/icons/damage/Context';
import TipCard from './components/TipCard/TipCard';
import SkipButton from 'src/components/SkipButton/SkipButton';
import { useFeatureStore } from 'src/stores/featureStore';

const DamageExample = () => {
  const navigate = useNavigate();
  const allowDamage = useFeatureStore((state) => state.enableDamage);
  const isTipsVisible = useFeatureStore((state) => state.tipsVisible);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const damageExampleCopy = useEntityConfigStore((state) => state.copy.damageExample);

  const tips = [
    {
      title: damageExampleCopy.tips.closeUp.heading,
      subtitle: damageExampleCopy.tips.closeUp.tip,
      img: <CloseUp className="stroke-icons-color" />,
    },
    {
      title: damageExampleCopy.tips.context.heading,
      subtitle: damageExampleCopy.tips.context.tip,
      img: <Context className="stroke-icons-color" />,
    },
  ];

  const handleSkip = () => {
    if (!isTipsVisible) navigate('../capture-room');
    else navigate('../tips');
  };

  return (
    <>
      <DamageHeader variant="secondary" hideRestart={!allowDamage} buttonRight={!allowDamage ? <SkipButton testId="skip-damages-btn" text="skip damages" onClick={handleSkip} /> : undefined} />
      <ContentView variant="secondary" className="min-h-screen">
        <div className="px-4 pb-24">
          <div className="w-full desktops:w-desktop desktops:px-0 max-w-desktop pb-8">
            <div className="pb-3">
              <Text variant="title">{damageExampleCopy.title}</Text>
            </div>
            <Text variant="subtitle">{damageExampleCopy.subtitle}</Text>
          </div>

          <div className="pt-15">
            {tips.map(({ title, subtitle, img }, i) => (
              <TipCard key={`damage-ex-tips-${i}`} borderBottom={i !== tips.length - 1} img={img} title={title} subtitle={subtitle} />
            ))}
          </div>
        </div>
        <Footer extraClassname="bg-white pt-4 border-t">
          <BaseButton
            dataTestId="start-damage-capture"
            name={analytics.captureDamageExample}
            onClick={() => navigate(`../damage`)}
            label="start damage capture"
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        </Footer>
      </ContentView>
    </>
  );
};

export default DamageExample;
