import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import * as slack from 'src/utils/slack';

type Log = {
  event: string;
  data: any;
  type?: 'Success' | 'Info' | 'Error';
  ignoreSlack?: boolean;
};

const commonLog = async ({ event, data, type = 'Info' }: Log) => {
  mixpanel.track(event, { ...data, type });
  const template = slack.template({
    type,
    template: data.template,
    data: { ...data, header: data.header || event },
  });
  await slack.postMessage(template);
};

export const event = ({ event, data }: Log) => {
  info({ event, data });
  Sentry.addBreadcrumb({
    message: event,
    level: 'info',
    timestamp: Date.now(),
    data,
  });
};

export const success = ({ event, data }: Log) => {
  (async () => {
    try {
      await commonLog({ event, data, type: 'Success' });
    } catch (e) {
      Sentry.captureException(e);
    }
  })();
};

export const info = ({ event, data }: Log) => {
  (async () => {
    try {
      await commonLog({ event, data, type: 'Info' });
    } catch (e) {
      Sentry.captureException(e);
    }
  })();
};

export const error = ({ event, data, ignoreSlack = false }: Log) => {
  (async () => {
    try {
      const type = 'Error';
      if (!ignoreSlack) {
        await commonLog({ event, data, type });
      } else {
        mixpanel.track(event, { ...data, type });
      }
      if (data.error && data.error instanceof Error) {
        Sentry.captureException(data.error);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  })();
};
