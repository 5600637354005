import { dexieDb } from 'src/utils/indexedDb';
import { base64ToFile } from 'src/utils/utils';
import { getImageLink } from 'src/REST/capture';

type UploaderParams = {
  imgId: number;
  roomId: string;
  claimId: string;
  content?: string;
  abortController?: AbortController;
  recaptureKey?: string | undefined;
  imageField?: 'rejectedImages' | 'overviewImages';
};

const getBase64StringFromDataURL = (dataURL: string) => dataURL.replace('data:', '').replace(/^.+,/, '');

const imageUploader = async ({ imgId, claimId, roomId, content = '', recaptureKey, imageField }: UploaderParams) => {
  const file = base64ToFile(getBase64StringFromDataURL(content));
  return await getImageLink(claimId, roomId, file, 'jpeg', imgId.toString(), imageField, undefined, undefined, {}, [], undefined, recaptureKey);
};

export const uploadRoomImage = async ({ imgId, claimId, roomId, abortController, recaptureKey, imageField = 'overviewImages' }: UploaderParams) => {
  const img = await dexieDb.pictures.get(imgId);
  if (img && !img.uploaded && img.uploadId) {
    let extraImage;
    const image = (await imageUploader({
      claimId,
      roomId,
      imgId: img.uploadId,
      content: img.content,
      abortController,
      recaptureKey,
      imageField,
    })) as any;
    if (img.extraImages && img.extraImages.length > 0) {
      extraImage = await imageUploader({
        claimId,
        roomId,
        imgId: img.uploadId + 1,
        content: img.extraImages[0],
        recaptureKey,
        imageField,
      });
    }

    await dexieDb.pictures.update(img.id, {
      uploaded: true,
      awsKey: image?.key,
    });

    return {
      image,
      extraImage,
    };
  }

  return {
    image: img,
    extraImage: img?.extraImages?.[0],
  };
};
