import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import useValidateLink from 'src/hooks/useValidateLink/useValidateLink';
import useEntity from 'src/hooks/useEntity/useEntity';
import * as log from 'src/utils/logger';
import mixpanel from 'mixpanel-browser';
import Modal from 'src/components/Modal/Modal';
import Loading from 'src/components/Loading/Loading';
import flagsmith from 'flagsmith';
import { useFeatureStore } from 'src/stores/featureStore';
import * as Sentry from '@sentry/react';
import { getRecaptureInfo } from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';

const RecaptureRootPage = () => {
  const [time, setTime] = useState(new Date().getTime());
  const [page, setPage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { claimId, roomId, captureType, linkId } = useParams();
  const [, setIsTest] = useLocalStorage('test', false);
  const { error, data } = useValidateLink(linkId || claimId || 'not-found');
  const loaded = useEntity(data?.entity);
  const toggleDamage = useFeatureStore((state) => state.toggleDamage);
  const setRoomInfo = useClaimInfoStore((state) => state.setRecaptureInfo);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsTest(captureType === 'test');
    // eslint-disable-next-line
  }, [captureType]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const timeSpent = (currentTime - time) / 1000;
    setTime(currentTime);
    setPage(location.pathname);
    log.info({
      event: 'Time Spent',
      data: { pageName: page.split('/').pop() || page, timeSpent },
    });
    mixpanel.track(page.split('/').pop() || page, {
      timeSpent,
      claimId,
      roomId,
    });
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (data) {
      if (flagsmith.initialised) {
        mixpanel.identify(data.submitter);
        Sentry.setUser({ email: data.submitter });
        mixpanel.people.set_once('$email', data.submitter);
        flagsmith.identify(data.submitter);
        flagsmith.setTraits({
          enableAIAssistance: data.enableAIAssistance,
          allowDamage: data.allowDamage,
          displayName: data.displayName,
          environment: process.env.REACT_APP_ENV || 'LOCAL',
          entity: data.entity,
          submitter: data.submitter,
        });
      }
      toggleDamage(data.allowDamage);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    getRecaptureInfo(claimId, roomId)
      .then((resp) => {
        setLoading(true);
        const { data } = resp;
        setRoomInfo({
          rejectionCount: data?.roomInfo?.rejectionCount,
          roomType: data?.roomInfo?.roomType,
          isRecapture: data?.roomInfo?.status === 'Not Processable',
          status: data?.roomInfo?.status,
        });

        if (data?.roomInfo?.status === 'Initiated') {
          navigate(`/${captureType}/${claimId}/${roomId}/damage-example`);
        }
      })
      .catch((err) => {
        setLoading(false);
        setRoomInfo({
          rejectionCount: 0,
          roomType: 'other',
          isRecapture: false,
          status: 'Unknown',
        });
      });
  }, [captureType, claimId, navigate, roomId, setRoomInfo]);

  return (
    <>
      {loaded || error !== null ? <Outlet /> : null}
      {error ? <Modal noCloseHandlers title="Caution" text={error} /> : null}
      <Loading open={(!loaded && error === null) || !loading} />
    </>
  );
};

export default RecaptureRootPage;
