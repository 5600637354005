import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/WarningAmber';
import Text from 'src/components/Text/Text';
import ImageSlider from './slider';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { convertInputImageToBase64 } from 'src/utils/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppHeader from 'src/components/Header/RecaptureHeader';
import HelpContext from 'src/components/HelpModal';
import PhotoReviewComponent from './views/review';
import CompleteReview from './views/complete';
import SinglePhotoReview from './views/single';
import RightPhoto from './views/right';
import LeftPhoto from './views/left';
import CategorySelection from './views/category';
import { categories, checklist } from './utils';
import FinishCapture from './views/finish';
import Loader from './loader';
import { dexieDb } from 'src/utils/recaptureDb';
import { useLiveQuery } from 'dexie-react-hooks';
import moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';

export default function Capture({ imageData, handleClose, handleSubmit }: { imageData: any; handleClose: () => void; handleSubmit: () => void }) {
  const [data, setData] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [type, setType] = useState<string | null>(null);
  const [uploadType, setUploadType] = useState<string | null>('');
  const [active, setActive] = useState(0);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [mode, setMode] = useState<'left' | 'right' | 'preview' | 'complete' | 'finish' | 'single'>('left');
  const [open, setOpen] = useState(false);
  const currentData = useLiveQuery(async () => {
    const result = await dexieDb.recapture.where({ roomId: imageData?.roomId || -1 }).first();
    return result || { content: null, id: -1, roomId: -1 };
  }, [imageData?.roomId]);
  const [, setTipsCount] = useLocalStorage<number>('tipsCount', 0);

  useEffect(() => {
    setTipsCount(0);
  }, [setTipsCount]);

  useEffect(() => {
    if (currentData && currentData.id !== -1) {
      const oldString = currentData.content;
      const newString = JSON.stringify(data);
      console.log(oldString !== newString ? 'update' : 'no update');
      if (oldString !== newString) dexieDb.recapture.update(currentData.id, { content: JSON.stringify(data) });
    } else {
      if (data && imageData?.roomId)
        dexieDb.recapture.toArray().then((results) => {
          if (results.map((e) => e.roomId).includes(imageData?.roomId)) return;
          dexieDb.recapture.add({ id: results.length, content: JSON.stringify(data), roomId: imageData?.roomId, lastUpdated: moment().valueOf() });
        });
    }
  }, [data, currentData, imageData]);

  useEffect(() => {
    if (data || !currentData) return;
    if (imageData) {
      try {
        if (currentData.content) {
          if (moment().diff(moment(currentData.lastUpdated), 'minutes') > 15) {
            throw new Error('Data is too old. Resetting');
          }
          setData(JSON.parse(currentData.content));
        } else setData({ ...imageData, photos: imageData?.photos?.filter((e: any) => e.issues?.length > 0 || Boolean(e.graphics)) });
      } catch (e) {
        setData({ ...imageData, photos: imageData?.photos?.filter((e: any) => e.issues?.length > 0 || Boolean(e.graphics)) });
      }
    } else setData(undefined);
  }, [imageData, currentData, data]);

  const handleChange = (data: any) => {
    setActive(data?.realIndex);
  };

  const getTitle = () => {
    return categories.find((e) => e.type === type)?.name;
  };

  const getSubTitle = () => {
    return categories.find((e) => e.type === type)?.content;
  };

  const handleLeftPhoto = () => {
    if (uploadRef?.current) {
      setUploadType('left');
      uploadRef.current.value = '';
      (uploadRef.current as HTMLInputElement).click();
    }
  };

  const handleRightPhoto = () => {
    if (uploadRef?.current) {
      setUploadType('right');
      uploadRef.current.value = '';
      (uploadRef.current as HTMLInputElement).click();
    }
  };

  const handleOnePhoto = () => {
    if (uploadRef?.current) {
      setUploadType('one');
      uploadRef.current.value = '';
      (uploadRef.current as HTMLInputElement).click();
    }
  };

  const handleUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.target.files && evt.target.files.length > 0 && uploadType) {
      try {
        const file = evt.target.files[0];
        const image = await convertInputImageToBase64(file);
        setData({
          ...data,
          photos: data.photos.map((x: any, idx: number) => {
            if (idx === active) x[uploadType] = image;
            return x;
          }),
        });
        if (uploadType === 'left') setMode('right');
        if (uploadType === 'right') setMode('preview');
        if (uploadType === 'one') setMode('single');
      } catch (ex) {}
    }
  };

  const getNextId = () => {
    const nextId = data?.photos?.findIndex((e: any) => {
      if (!e.left && !e.right && !e.one) return true;
      return false;
    });
    return nextId;
  };

  const handleRetake = () => {
    if (type === null) setPage(1);
    else {
      setPage(2);
      setMode('left');
    }
  };

  const handleComplete = () => {
    const nextId = getNextId();
    if (nextId === -1) {
      setMode('finish');
    } else setMode('complete');
  };

  const handleSingleItemFinish = () => {
    const nextId = getNextId();
    if (nextId === -1) {
      setPage(3);
      return;
    }
    setPage(0);
    setActive(nextId);
  };

  const handleFinsh = () => {
    const nextId = getNextId();
    if (nextId === -1) {
      return;
    }
    setPage(0);
    setActive(nextId);
  };

  const handleSingle = () => {
    setData({
      ...data,
      photos: data.photos.map((x: any, idx: number) => {
        if (idx === active) {
          x['one'] = x['left'];
          x['left'] = undefined;
        }
        return x;
      }),
    });
    setMode('single');
  };

  const getHeader = () => {
    if (mode === 'left') return 'Two Photo Capture';
    if (mode === 'complete' || mode === 'finish') return 'Confirm';
    return 'Review';
  };

  const handleBack = () => {
    if (page === 2) {
      if (mode === 'left') setPage(1);
      if (mode === 'right' || mode === 'single') setMode('left');
      if (mode === 'preview') setMode('right');
      return;
    }
    setType(null);
    setMode('left');
    setPage(0);
  };

  const handleSelect = (type: string) => {
    setType(type);
    setPage(2);
  };

  const roomType = categories.find((e) => e.type === type) || categories[0];

  const handleSubmitRoom = () => {
    handleSubmit();
  };

  return (
    <>
      <input hidden accept="image/*" onChange={handleUpload} ref={uploadRef} type="file" capture="environment" name="Capture-Image" />
      <HelpContext open={open} onClose={() => setOpen(false)} onSubmit={() => setOpen(false)} />
      {page === 0 && (
        <>
          <AppHeader label="Failed Images" onClose={handleClose} />
          <DialogContent>
            <div className="max-w-[500px] m-auto">
              <div className="flex flex-col gap-2">
                <Text align="left" variant="paragraph">
                  <WarningIcon color="warning" /> {data?.photos?.length} Image(s) Need Attention
                </Text>
                <ImageSlider navigation slideNumber={active} onChange={handleChange} images={data?.photos || []} />
                {data?.photos[active]?.issues?.map((x: string, idx: number) => {
                  return (
                    <p key={idx}>
                      <WarningIcon color="warning" /> {x}
                    </p>
                  );
                })}
              </div>
            </div>
          </DialogContent>
          <DialogActions className="max-w-[500px] mx-auto w-full">
            <div className="flex flex-col gap-2 w-full text-center">
              {((data?.photos[active]?.left && data?.photos[active]?.right) || data?.photos[active].one) && (
                <p>
                  <CheckCircleIcon color="success" /> Images Captured
                </p>
              )}
              <Button onClick={handleRetake} variant="contained" fullWidth>
                Retake Photo
              </Button>
            </div>
          </DialogActions>
        </>
      )}
      {page === 1 && <CategorySelection handleClose={handleBack} handleChange={setPage} data={data} active={active} categories={categories} handleSelect={handleSelect} />}
      {page === 2 && (
        <>
          <AppHeader label={getHeader()} onClose={handleBack} />
          <DialogContent>
            <div className="max-w-[500px] m-auto h-full">
              {mode === 'left' && (
                <LeftPhoto title={getTitle()} categories={categories} type={type} subtitle={getSubTitle()} roomType={roomType} handleOnePhoto={handleOnePhoto} handleLeftPhoto={handleLeftPhoto} />
              )}
              {mode === 'right' && <RightPhoto roomType={roomType} data={data} active={active} handleRightPhoto={handleRightPhoto} handleLeftPhoto={handleLeftPhoto} handleSingle={handleSingle} />}
              {mode === 'preview' && <PhotoReviewComponent roomType={roomType} data={data} active={active} handleComplete={handleComplete} handleRightPhoto={handleRightPhoto} />}
              {mode === 'single' && (
                <SinglePhotoReview data={data} active={active} checklist={checklist} getNextId={getNextId} handleSingleItemFinish={handleSingleItemFinish} handleOnePhoto={handleOnePhoto} />
              )}
              {mode === 'complete' && <CompleteReview data={data} active={active} checklist={checklist} handleFinish={handleFinsh} setMode={setMode} />}
              {mode === 'finish' && <FinishCapture data={data} active={active} handleRetake={() => setMode('left')} handleSubmit={() => setPage(3)} />}
            </div>
          </DialogContent>
        </>
      )}
      {page === 3 && <Loader handleSubmit={handleSubmitRoom} room={data} />}
    </>
  );
}
