import claimId from './common/claimId';
import submitter from './common/submitter';
import { useClaimInfoStore } from 'src/stores/claimInfo';

export type RoomTypeSelectionData = {
  roomId: string;
  type: string;
  remainingRooms: string;
};

const roomTypeSelection = (data: RoomTypeSelectionData) => {
  const claimInfo = useClaimInfoStore.getState();

  return [
    submitter(),
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Type:* ${data.type}`,
        },
        {
          type: 'mrkdwn',
          text: `*Remaining rooms:* ${data.remainingRooms}`,
        },
      ],
    },
    claimId(),
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Room id:*\n<${process.env.REACT_APP_BASE_URL}/#/app/processingInterface/${claimInfo.claimId}/${data.roomId}|${data.roomId}>`,
        },
      ],
    },
  ];
};

export default roomTypeSelection;
