import Text from 'src/components/Text/Text';
import Footer from 'src/components/Footer/Footer';
import BaseButton from 'src/components/BaseButton/BaseButton';
import React from 'react';

type Props = {
  photos: string[];
  onRetake: () => void;
  onConfirm: () => void;
};

const Review = ({ photos, onRetake, onConfirm }: Props) => {
  return (
    <div className="w-full bg-white flex flex-col items-center pb-36 min-h-full overflow-y-scroll">
      <div className="w-[80%] py-4">
        <div className="pb-3">
          <Text variant="title">Review</Text>
        </div>
        <Text variant="subtitle">{photos.length > 1 ? 'Can you see some floor and ceiling? Is there overlap between the 2 images?' : 'Can you see some floor and ceiling?'}</Text>
      </div>

      <div className="relative w-[90%]">
        {photos.map((photo, index) => (
          <div key={`photo-review-${index}`} className="w-full">
            <img alt="test" src={photo} className="w-full" />
          </div>
        ))}
        {photos.length > 1 && (
          <div className="flex flex-row justify-center items-center absolute w-full h-full top-0 left-0">
            <div className="flex flex-row justify-center items-center bg-cover h-[54px] w-full" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/overlap-pattern.svg)` }}>
              <div className="text-black h-[28px] text-center text-lg bg-white/80 overlap px-2">OVERLAP</div>
            </div>
          </div>
        )}
      </div>

      <Footer extraClassname="bg-white pt-2">
        <div className="text-icons-color pb-2 cursor-pointer" onClick={onRetake}>
          {photos.length > 1 ? 'Retake Photos' : 'Retake Photo'}
        </div>
        <BaseButton lightBg type="button" onClick={onConfirm} label="Looks Good" />
      </Footer>
    </div>
  );
};

export default Review;
