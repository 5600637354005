type Props = {
  className: string;
};

const Context = ({ className }: Props) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_24_9247)">
      <g opacity="0.6" clipPath="url(#clip1_24_9247)">
        <path
          d="M112.055 -7.43359L85.0603 10.5937V70.4845L112.055 94.2241V-7.43359Z"
          stroke="#2F7FF1"
          strokeWidth="1.25"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          d="M59.2547 73.1801C62.0491 72.2025 64.6269 72.2495 66.2693 72.5936C70.4364 73.4664 68.6253 75.4782 75.2335 76.2578C82.6891 77.1374 84.909 76.2578 84.909 78.2209C84.909 80.184 77.1812 79.5351 71.9405 79.8391C61.7319 80.4312 64.0873 85.1799 55.986 85.1799C49.1378 85.1799 34.5205 82.7839 38.8283 78.2209C41.651 75.231 51.4324 77.3654 55.248 76.2578C56.7392 75.8249 56.2551 74.2295 59.2547 73.1801Z"
          fill="#2F7FF1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.8714 58.188C63.8714 58.9129 63.2878 59.4971 62.5637 59.4971C61.8397 59.4971 61.2561 58.9129 61.2561 58.188C61.2561 57.4632 62.5637 55.7106 62.5637 55.7106C62.5637 55.7106 63.8714 57.4632 63.8714 58.188Z"
          fill="#CBDFFC"
          stroke="#2F7FF1"
          strokeWidth="0.786724"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="16.4465"
          y="30.4926"
          width="5.50591"
          height="28.3957"
          rx="2.75295"
          stroke="#2F7FF1"
          strokeWidth="0.9375"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="26.647"
          y="30.4926"
          width="5.50591"
          height="28.3957"
          rx="2.75295"
          stroke="#2F7FF1"
          strokeWidth="0.9375"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="36.8472"
          y="30.4927"
          width="5.50591"
          height="28.3957"
          rx="2.75295"
          stroke="#2F7FF1"
          strokeWidth="0.9375"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="47.0481"
          y="30.4927"
          width="5.50591"
          height="28.3957"
          rx="2.75295"
          stroke="#2F7FF1"
          strokeWidth="0.9375"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="12.9255"
          y="26.9716"
          width="43.1493"
          height="35.4376"
          rx="2.34729"
          stroke="#2F7FF1"
          strokeWidth="1.25"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          d="M63.8217 46.8816C64.7241 46.8816 65.4556 47.6131 65.4556 48.5155V48.5155C65.4556 49.4178 64.7241 50.1493 63.8217 50.1493L56.0748 50.1493L56.0748 46.8816L63.8217 46.8816Z"
          stroke="#2F7FF1"
          strokeWidth="0.786724"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <path
          d="M63.8217 51.4272C64.7241 51.4272 65.4556 52.1587 65.4556 53.0611V53.0611C65.4556 53.9634 64.7241 54.6949 63.8217 54.6949L56.0748 54.6949L56.0748 51.4272L63.8217 51.4272Z"
          stroke="#2F7FF1"
          strokeWidth="0.786724"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <line
          x1="62.725"
          y1="52.6471"
          x2="62.7273"
          y2="53.6849"
          stroke="#2F7FF1"
          strokeWidth="0.786724"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="-8.69824"
          y="10.5729"
          width="93.7586"
          height="66.8833"
          stroke="#2F7FF1"
          strokeWidth="1.25"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
        <rect
          x="17.6895"
          y="-1.42413"
          width="7.66351"
          height="28.3957"
          fill="#F5F9FF"
          stroke="#2F7FF1"
          strokeWidth="1.25"
          strokeMiterlimit="22.9256"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={className}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5652 103.681C24.5652 103.681 -8.65915 108.035 -6.65076 94.8099C-4.70933 70.996 14.6026 65.1079 14.6026 65.1079V72.8577L6.45748 77.616C2.70849 79.8053 0.895761 84.1658 0.0254612 88.1199C-0.532423 90.7113 -1.08512 92.6505 -0.147872 95.3536C1.65967 100.626 5.38634 104.245 9.69321 104.245C13.219 104.245 16.3432 101.832 18.3516 98.0566C18.9318 98.2577 17.7044 80.8621 18.3516 80.8621C18.3516 80.8621 46.8008 72.3174 45.0234 83.8243C43.2461 95.3312 36.8609 102.81 24.5652 103.681Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4551 57.3994C62.9377 54.7633 58.8317 54.2719 55.2835 56.3271C51.7354 58.3823 50.1064 62.18 51.6238 64.8161C53.1413 67.4521 57.2473 67.9436 60.7954 65.8884C64.3436 63.8331 65.9726 60.0354 64.4551 57.3994Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4551 69.505C62.9377 66.8689 58.8317 66.3775 55.2835 68.4327C51.7354 70.4879 50.1064 74.2856 51.6238 76.9217C53.1413 79.5578 57.2473 80.0492 60.7954 77.994C64.3436 75.9388 65.9726 72.1411 64.4551 69.505Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M15.1946 65.2357C15.1946 65.2357 -4.58151 70.3573 -6.52295 94.1711"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path d="M44.3454 86.6613L43.1272 90.8183L44.7755 91.1767L47.3557 90.8183L51.6559 89.6L53.9494 82.6478H44.7755L44.3454 86.6613Z" fill="white" />
      <path
        d="M45.2121 81.0059C48.7602 79.2102 52.8662 79.6396 54.3837 81.9429C55.9011 84.2461 54.2721 87.5644 50.724 89.3602C47.917 90.7808 45.2119 91.5827 43.1824 90.874"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.1137 81.5638C58.8048 79.2359 55.2631 78.8019 52.2026 80.6168C49.1421 82.4318 47.7369 85.7855 49.0458 88.1134C50.3547 90.4412 53.8964 90.8753 56.9569 89.0603C60.0174 87.2454 61.4226 83.8916 60.1137 81.5638Z"
        fill="white"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M44.8262 84.2194C42.9415 96.401 34.3968 104.266 22.1011 105.138"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <g clipPath="url(#clip2_24_9247)">
        <rect x="8.62891" y="83.572" width="43.4531" height="86.4578" rx="6.05871" transform="rotate(-90 8.62891 83.572)" fill="white" />
        <g clipPath="url(#clip3_24_9247)">
          <rect width="87.2455" height="50.8932" transform="translate(7.84131 32.8484)" fill="white" />
          <path
            d="M95.0867 32.3178H75.214V70.8246L95.0867 84.5364V32.3178Z"
            stroke="#2F7FF1"
            strokeWidth="1.08298"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <path
            d="M7.80444 32.3178H24.6225V70.8246L7.80444 84.5364V32.3178Z"
            stroke="#2F7FF1"
            strokeWidth="0.908807"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <path
            d="M60.8011 73.4761C62.4151 72.9197 63.904 72.9465 64.8527 73.1423C67.2595 73.639 66.2135 74.7838 70.0303 75.2275C74.3366 75.7281 75.6188 75.2275 75.6188 76.3446C75.6188 77.4618 71.1552 77.0925 68.1283 77.2655C62.2319 77.6025 63.5923 80.3048 58.9131 80.3048C54.9577 80.3048 46.5149 78.9413 49.003 76.3446C50.6334 74.6432 56.283 75.8578 58.4868 75.2275C59.3481 74.9812 59.0685 74.0733 60.8011 73.4761Z"
            fill="#CBDFFC"
            stroke="#2F7FF1"
            strokeWidth="0.721987"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.9756 63.7224C62.9756 64.1411 62.6386 64.4785 62.2204 64.4785C61.8022 64.4785 61.4651 64.1411 61.4651 63.7224C61.4651 63.3037 62.2204 62.2914 62.2204 62.2914C62.2204 62.2914 62.9756 63.3037 62.9756 63.7224Z"
            fill="#CBDFFC"
            stroke="#2F7FF1"
            strokeWidth="0.454404"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="35.5828"
            y="47.7257"
            width="3.18015"
            height="16.4011"
            rx="1.59008"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="41.4749"
            y="47.7256"
            width="3.18015"
            height="16.4011"
            rx="1.59008"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="47.3665"
            y="47.7257"
            width="3.18015"
            height="16.4011"
            rx="1.59008"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="53.2578"
            y="47.7256"
            width="3.18015"
            height="16.4011"
            rx="1.59008"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="33.5493"
            y="45.692"
            width="24.9226"
            height="20.4684"
            rx="1.35577"
            stroke="#2F7FF1"
            strokeWidth="0.721987"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <path
            d="M62.9469 57.1918C63.4681 57.1918 63.8906 57.6143 63.8906 58.1355V58.1355C63.8906 58.6567 63.4681 59.0792 62.9469 59.0792L58.4724 59.0792L58.4724 57.1918L62.9469 57.1918Z"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="41.4751"
            y="34.3214"
            width="11.3705"
            height="3.77427"
            transform="rotate(90 41.4751 34.3214)"
            stroke="#2F7FF1"
            strokeWidth="0.721987"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <path
            d="M62.9467 59.8174C63.4679 59.8174 63.8904 60.2399 63.8904 60.7611V60.7611C63.8904 61.2823 63.4679 61.7048 62.9467 61.7048L58.4722 61.7048L58.4722 59.8174L62.9467 59.8174Z"
            stroke="#2F7FF1"
            strokeWidth="0.693107"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <line
            x1="62.3132"
            y1="60.5219"
            x2="62.3145"
            y2="61.1214"
            stroke="#2F7FF1"
            strokeWidth="0.454404"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
          <rect
            x="24.6072"
            y="32.0536"
            width="50.6067"
            height="38.7709"
            stroke="#2F7FF1"
            strokeWidth="1.08298"
            strokeMiterlimit="22.9256"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
          />
        </g>
      </g>
      <rect
        x="8.62891"
        y="83.572"
        width="43.4531"
        height="86.4578"
        rx="6.05871"
        transform="rotate(-90 8.62891 83.572)"
        stroke="#2F7FF1"
        strokeWidth="3.01693"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6202 85.9407L23.4529 82.9048L31.0889 81.7616C40.7333 80.3178 41.7855 66.6976 35.0985 67.6987L19.8706 69.9784C16.7808 70.4409 14.2196 71.5698 11.7807 73.5161L4.37027 79.482C-1.22922 83.9796 -2.84013 91.6072 0.463873 98.002C3.03207 102.948 7.25347 105.976 11.5129 105.338C17.5158 104.439 21.306 96.5985 19.9963 87.8496C19.9003 87.2089 19.7823 86.5715 19.6235 85.9628L19.6202 85.9407Z"
        fill="white"
      />
      <path
        d="M3.05829 81.3322L11.7622 73.5426C14.201 71.5963 16.7623 70.4675 19.852 70.0049L35.0799 67.7252C41.767 66.7241 40.7147 80.3443 31.0704 81.7881L23.4344 82.9313L19.6017 85.9672C19.7605 86.5759 19.8785 87.2133 19.9744 87.854C20.4311 90.9048 20.2655 93.8465 19.6017 96.422"
        stroke="#2F7FF1"
        strokeWidth="1.21691"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M95.0869 72.4129L95.0869 49.7628L92.5626 49.7628C91.5126 49.7628 90.6414 50.6331 90.6414 51.6819L90.6414 70.4938C90.6414 71.5426 91.5126 72.4129 92.5626 72.4129L95.0869 72.4129Z"
        fill="#2F7FF1"
        className={`${className} fill-icons-color`}
      />
      <path
        d="M92.5859 54.2929C92.5859 54.7242 92.936 55.0739 93.3678 55.0739C93.7996 55.0739 94.1497 54.7242 94.1497 54.2929C94.1497 53.8615 93.7996 53.5118 93.3678 53.5118C92.936 53.5118 92.5859 53.8615 92.5859 54.2929Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1731 57.8183L94.1731 64.4013C94.1731 64.8253 93.8157 65.1823 93.3912 65.1823C92.9668 65.1823 92.6093 64.8253 92.6093 64.4013L92.6093 57.8183C92.6093 57.3943 92.9668 57.0372 93.3912 57.0372C93.8157 57.0372 94.1731 57.3943 94.1731 57.8183Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_24_9247">
        <rect width="100" height="100" fill="white" />
      </clipPath>
      <clipPath id="clip1_24_9247">
        <rect width="100" height="100" fill="white" />
      </clipPath>
      <clipPath id="clip2_24_9247">
        <rect x="8.62891" y="83.572" width="43.4531" height="86.4578" rx="6.05871" transform="rotate(-90 8.62891 83.572)" fill="white" />
      </clipPath>
      <clipPath id="clip3_24_9247">
        <rect width="87.2455" height="50.8932" fill="white" transform="translate(7.84131 32.8484)" />
      </clipPath>
    </defs>
  </svg>
);

export default Context;
