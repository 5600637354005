import ContentView from 'src/components/Content/Content';
import DamageHeader from 'src/components/Header/DamageHeader';
import { getOverViewImages, getTotalPhotos } from './RoomLayoutCapture';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import Text from 'src/components/Text/Text';
import BaseButton from 'src/components/BaseButton/BaseButton';
import Footer from 'src/components/Footer/Footer';
import React, { useState } from 'react';
import { dexieDb, handleError } from 'src/utils/indexedDb';
import LayoutCapture from 'src/components/layoutCapture/LayoutCapture';
import Grid from '@mui/material/Unstable_Grid2';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';

const RoomLayoutCapturePhotoReview = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const overviewImages = useLiveQuery(async () => getOverViewImages(roomId), [roomId]);
  const addImgToQueue = useUploadQueueStore((state) => state.add);
  const [captureOpen, setCaptureOpen] = useState<boolean>(false);

  const totalPhotos = async () => {
    try {
      const photos = await getOverViewImages(roomId);
      return getTotalPhotos(photos);
    } catch (e) {
      handleError(e);
    }
  };

  const handleAddImages = async (photos: string[]) => {
    const errors: string[] = [];
    const imgId = (overviewImages?.length || 0) + 1;
    const nextId = (await totalPhotos()) || 0;

    try {
      await dexieDb.pictures.add({
        roomId,
        errors,
        id: imgId,
        uploadId: nextId + 1,
        content: photos[0],
        imgType: 'overviewImage',
        ...(photos.length > 1 && { extraImages: [photos[1]] }),
      });
      addImgToQueue({ id: imgId, abortController: new AbortController() });

      log.event({
        event: eventNames.CAPTURE_COMPLETE,
        data: {
          id: imgId,
          roomId: roomId,
          hasExtraImage: photos.length > 1,
        },
      });
    } catch (e) {
      handleError(e);
      log.error({ event: eventNames.CAPTURE_ERROR, data: { error: e } });
    } finally {
      setCaptureOpen(false);
    }
  };

  const handleComplete = () => {
    navigate('../confirm-photos');
  };

  const openCamera = () => {
    setCaptureOpen(true);
    log.event({ event: eventNames.OPEN_LAYOUT_CAPTURE, data: { roomId } });
  };

  return (
    <>
      <DamageHeader hideRestart />
      <ContentView className="gap-2 min-h-screen -mt-14 pt-14 pb-36" variant="primary">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="pb-10">
            <div className="pb-3">
              <Text variant="title">Next Photo</Text>
            </div>
            <Text variant="subtitle">
              {`Every wall in the room must be shown.
                Each photo must show some floor & ceiling.
                Each photo must overlap with the previous & next photo so that there are no gaps.`}
            </Text>
          </div>
          <Grid container columns={12} spacing={1}>
            {overviewImages?.map((image, i) => (
              <Grid xs={6} key={image.id}>
                <div className="w-full bg-[#F2F2F2] p-2">
                  <p>IMAGE {i + 1}</p>
                  <img src={image.content} alt="room" className="w-full" />
                  {image.extraImages?.map((extraImage, index) => <img key={index} src={extraImage} alt="room" className="w-full" />)}
                </div>
              </Grid>
            ))}
          </Grid>

          <Footer extraClassname="bg-white pt-2">
            {(overviewImages?.length || 0) >= 4 && <BaseButton outlined className="mt-2" lightBg type="button" onClick={openCamera} label="Add More Photos" />}
            <BaseButton className="mt-2" lightBg type="button" onClick={handleComplete} label="Submit photos" />
          </Footer>
        </div>
      </ContentView>
      {captureOpen && <LayoutCapture isOpen={captureOpen} onClose={() => setCaptureOpen(false)} onComplete={handleAddImages} />}
    </>
  );
};

export default RoomLayoutCapturePhotoReview;
