import classnames from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import * as log from 'src/utils/logger';

interface IBaseButtonProps {
  className?: string;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  outlined?: boolean;
  icon?: string;
  type?: 'button' | 'submit';
  name?: string;
  selected?: boolean;
  flexDirection?: 'row' | 'col';
  textAlign?: 'left' | 'right' | 'center';
  textIcon?: string;
  textIconColor?: string;
  testId?: string;
}

const ButtonImage: React.FC<IBaseButtonProps> = (props) => {
  const { className, label, disabled, loading, onClick, outlined, icon, type, name, selected, textAlign = 'left', flexDirection = 'row', textIcon, textIconColor, testId = 'button-test-id' } = props;
  const { claimId, roomId } = useParams();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
    if (name) {
      log.event({ event: name, data: { claimId, roomId } });
    }
  };

  return (
    <button
      data-testid={testId}
      type={type || 'button'}
      disabled={loading || disabled}
      className={`${disabled ? '' : outlined ? 'border border-primary' : 'bg-primary text-white'} ${selected ? 'bg-blue-100 border-blue-800 text-blue-800' : ''} hover:opacity-75 rounded-lg w-full ${disabled ? 'bg-slate-400 opacity-30 cursor-not-allowed' : 'cursor-pointer'} ${className || ''} px-4 py-2 `}
      onClick={loading || disabled ? () => {} : handleClick}>
      <div
        className={classnames(`flex items-center flex-${flexDirection}`, {
          'gap-2': !textIcon,
          'gap-1': textIcon,
        })}>
        <div className="flex items-center">
          <img
            src={icon}
            className={classnames({
              'm-2': !textIcon,
              'w-[30px] h-[30px]': !textIcon,
              'w-[20px] h-[20px]': textIcon,
            })}
            alt="Icon"
          />
          {textIcon && <p className={`${textIconColor || ''} font-bold tex-sm`}>{textIcon}</p>}
        </div>
        <p className={`text-${textAlign}`}>{label}</p>
      </div>
    </button>
  );
};

export default ButtonImage;
