import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Text from 'src/components/Text/Text';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';
import { useRef } from 'react';

function RoomLayoutCaptureTips() {
  const navigate = useNavigate();
  const swiper = useRef<SwiperRef>(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const tips = [
    {
      title: 'Room Capture',
      subtitle: `Take photos of all the walls in your room to create a floor plan for your claim.`,
      description: (
        <span>
          Work your way around the perimeter of your space capturing <span className="font-bold">every wall.</span>
        </span>
      ),
      source: '/videos/360-degree-capture.mp4',
      type: 'video',
      list: ['Angles and perspective are OK.', 'No need to move any furniture.'],
    },
    {
      title: 'Important Details',
      subtitle: '',
      description: '',
      source: '/videos/360-stitching-darkroom.mp4',
      type: 'video',
      list: [
        <p>
          There should be <span className="font-bold">overlap between photos</span>
        </p>,
        <p>
          Include <span className="font-bold">some ceiling and some floor</span> in every photo
        </p>,
      ],
    },
  ];

  const handleNext = () => {
    log.event({ event: eventNames.NEXT_TIP, data: 'Tip ' + (activeStep + 1) });
    if (activeStep === 1) {
      navigate('../capture-room');
      return;
    }

    swiper?.current?.swiper?.slideNext?.();
  };

  const handlePrev = () => {
    if (activeStep === 0) {
      navigate(-1);
      return;
    }

    swiper?.current?.swiper?.slidePrev?.();
  };

  return (
    <>
      <DamageHeader handleBack={handlePrev} variant="primary" hideRestart />
      <ContentView variant="primary" className="min-h-screen">
        <div className="w-full flex-1 flex flex-col items-center">
          <div className="w-full desktops:w-desktop px-2 desktops:px-0 max-w-desktop flex flex-col items-center flex-1">
            <Swiper ref={swiper} onSlideChange={(s: any) => setActiveStep(s.activeIndex)} className="mySwiper">
              {tips.map((tip, index) => (
                <SwiperSlide style={{ background: 'transparent' }} key={index}>
                  <div className="flex flex-col gap-5">
                    <Text variant="title">{tip.title}</Text>
                    {tip.subtitle && (
                      <Text variant="subtitle" align="left">
                        {tip.subtitle}
                      </Text>
                    )}
                    {tip.type === 'image' && (
                      <div className="w-full">
                        <img alt="Tip Gif" className="w-full py-10" src={tip.source} />
                      </div>
                    )}
                    {tip.type === 'video' && (
                      <div>
                        <video autoPlay loop muted playsInline>
                          <source src={tip.source} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    {tip.description && (
                      <Text variant="subtitle" align="left">
                        {tip.description}
                      </Text>
                    )}
                    {tip.list && (
                      <div className="flex flex-col mt-4">
                        {tip.list.map((item, idx) => (
                          <div key={idx} className="flex pb-4">
                            <LabelImportantIcon className="mr-4" />
                            <Text variant="subtitle" align="left">
                              {item}
                            </Text>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Footer>
          <BaseButton dataTestId="next-tips-btn" name="Next Tip" onClick={handleNext} label="Next" />
        </Footer>
      </ContentView>
    </>
  );
}

export default RoomLayoutCaptureTips;
