import Button from '@mui/material/Button';

type Props = {
  text: string;
  onClick: () => void;
  testId: string;
};

const SkipButton = ({ onClick, text, testId }: Props) => {
  return (
    <Button data-testid={testId} onClick={onClick} className="text-sm !text-icons-color p-2" variant="text" style={{ textTransform: 'capitalize' }}>
      {text}
    </Button>
  );
};

export default SkipButton;
