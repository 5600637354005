import classnames from 'classnames';
import type { PropsWithChildren } from 'react';

type Props = {
  topClassName?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
};

const ContentView = ({ children, className, topClassName, variant = 'primary' }: PropsWithChildren<Props>) => {
  return (
    <div
      className={classnames(`w-full flex-1 flex flex-col items-center h-full pt-14 ${topClassName || ''}`, {
        'bg-screen-bg-primary': variant === 'primary',
        'bg-screen-bg-secondary': variant === 'secondary',
      })}>
      <div className={`max-w-[500px] w-full damage-content desktops:w-desktop px-4 flex flex-col items-center flex-1 ${className || ''}`}>{children}</div>
    </div>
  );
};

export default ContentView;
