import { create } from 'zustand';

export type EventStore = {
  loading: boolean;
  allowClose: boolean;
  setLoading: (loading: boolean) => void;
  error: string;
  setError: (error: string, allowClose?: boolean) => void;
};

export const useEventStore = create<EventStore>((set) => ({
  error: '',
  allowClose: false,
  loading: false,
  setLoading: (loading: boolean) => set(() => ({ loading })),
  setError: (error: string, allowClose?: boolean) => set(() => ({ error, allowClose: allowClose || false })),
}));
