import { useClaimInfoStore } from 'src/stores/claimInfo';

const submitter = () => {
  const claimInfo = useClaimInfoStore.getState();
  return {
    type: 'section',
    fields: [
      {
        type: 'mrkdwn',
        text: `*Submitter:*\n ${claimInfo.submitter}`,
      },
      {
        type: 'mrkdwn',
        text: `*Company:*\n ${claimInfo.displayName}`,
      },
    ],
  };
};

export default submitter;
