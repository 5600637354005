import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Webcam from 'react-webcam';
import classnames from 'classnames';
import Footer from 'src/components/Footer/Footer';
import IconButton from '@mui/material/IconButton';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import * as log from 'src/utils/logger';
import { eventNames } from 'src/utils/events';
import { useParams } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { useLocalStorage } from 'usehooks-ts';
import Review from './Review';
import Camera from './Camera';
import useCheckZoomSupport from 'src/hooks/useCheckZoomSupport';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  onComplete?: (photos: string[]) => void;
  captureMode?: 'one-photo' | 'two-photos';
};

type Mode = 'one-photo' | 'two-photos' | 'review';
type Timeout = ReturnType<typeof setTimeout>;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const joyRideSteps = [
  {
    content: (
      <h2>
        Use the guidelines to ensure <span className="font-bold">some ceiling is showing.</span> This is just a loose guide, it does not need to be exact.
      </h2>
    ),
    target: '.ceiling-frame',
    disableBeacon: true,
  },
  {
    content: (
      <h2>
        Use the guidelines to ensure <span className="font-bold">some floor is showing.</span> This is just a loose guide, it does not need to be exact.
      </h2>
    ),
    target: '.floor-frame',
  },
  {
    content: <h2>Try a 2-photo layout if you cannot fit ceiling, wall and floor in a single photo.</h2>,
    target: '.layout-btns',
  },
  {
    content: <h2>When using 2-photo capture, ensure there is some overlap between your photographs.</h2>,
    target: '.overlap',
  },
];

const LayoutCapture = ({ isOpen, onClose, onComplete, captureMode = 'one-photo' }: Props) => {
  const { roomId } = useParams();
  const webcamOneRef = useRef<Webcam | null>(null);
  const webcamTwoRef = useRef<Webcam | null>(null);
  const twoPhotosSuggestionRef = useRef<Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [mode, setMode] = useState<Mode>(captureMode);
  const [photos, setPhotos] = useState<string[]>([]);
  const [steps, setSteps] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [tipsWatched, setTipsWatched] = useLocalStorage<boolean>('tips-watched', false);
  const [twoPhotosSuggestion, setTwoPhotosSuggestion] = useLocalStorage<boolean>('two-photos-suggestion', false);

  const { zoom, status } = useCheckZoomSupport(webcamOneRef);

  useEffect(() => {
    if (status === 'success') {
      setSteps(joyRideSteps);
    }
  }, [status]);

  useEffect(() => {
    if (tipsWatched && mode === 'one-photo' && photos.length === 0 && !twoPhotosSuggestion) {
      twoPhotosSuggestionRef.current = setTimeout(() => {
        setAlertOpen(true);
      }, 1000 * 10);
    }
    return () => {
      clearTwoPhotosSuggestionModal();
    };
  }, [mode, tipsWatched, photos, twoPhotosSuggestion]);

  useEffect(() => {
    if (photos.length === 2 && mode === 'two-photos') {
      setMode('review');
      clearTwoPhotosSuggestionModal();
      log.event({ event: eventNames.ROOM_REVIEW, data: { roomId, mode } });
    }

    if (photos.length === 1 && mode === 'one-photo') {
      setMode('review');
      clearTwoPhotosSuggestionModal();
      log.event({ event: eventNames.ROOM_REVIEW, data: { roomId, mode } });
    }
  }, [photos, mode, roomId]);

  const capture = React.useCallback(() => {
    clearTwoPhotosSuggestionModal();

    if (!photos.length) {
      const imageSrc = webcamOneRef.current?.getScreenshot() || '';
      setPhotos((prevState) => [...prevState, imageSrc]);
    }

    if (photos.length === 1) {
      const imageSrc = webcamTwoRef.current?.getScreenshot() || '';
      setPhotos((prevState) => [...prevState, imageSrc]);
    }
  }, [photos]);

  const handleConfirm = () => {
    onComplete?.(photos);
    setPhotos([]);
    setMode(captureMode);
  };

  const clearTwoPhotosSuggestionModal = () => {
    twoPhotosSuggestionRef.current && clearTimeout(twoPhotosSuggestionRef.current);
  };

  const handleRetake = () => {
    setPhotos([]);
    const mode = photos.length === 1 ? 'one-photo' : 'two-photos';
    setMode(mode);
    log.event({ event: eventNames.RETAKE_PHOTO, data: { roomId, mode } });
  };

  const handleModeChange = (mode: Mode) => {
    setPhotos([]);
    setMode(mode);
    clearTwoPhotosSuggestionModal();
    log.event({ event: eventNames.CHANGE_CAPTURE_LAYOUT, data: { roomId, mode } });
  };

  const handleClose = (logEvent: boolean) => {
    setAlertOpen(false);
    setTwoPhotosSuggestion(true);
    if (logEvent) {
      log.event({ event: eventNames.DISMISS_TWO_PHOTO_LAYOUT_SUGGESTION, data: { roomId } });
    }
  };

  const handleTwoPhotoCapture = () => {
    handleClose(false);
    setMode('two-photos');
    log.event({ event: eventNames.TWO_PHOTO_LAYOUT_FROM_SUGGESTION, data: { roomId } });
  };

  const isCaptureMode = ['one-photo', 'two-photos'].includes(mode);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        className: isCaptureMode ? '!bg-black' : '!bg-white',
      }}
      ref={contentRef}>
      <Joyride
        steps={steps}
        run={!tipsWatched}
        continuous
        scrollToFirstStep
        hideCloseButton
        hideBackButton
        callback={(data) => {
          const { status } = data;
          const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
          if (data.index === 2) {
            setMode('two-photos');
          }
          if (finishedStatuses.includes(status)) {
            setMode('one-photo');
            setTipsWatched(true);
          }
        }}
        locale={{
          last: 'Start Capture',
        }}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#1D66CF',
          },
        }}
      />
      {isCaptureMode && (
        <div className="w-full min-h-full bg-black flex flex-col items-center">
          <div className="flex flex-row justify-start w-[90%] relative">
            <div className="absolute z-[15] top-2">
              <IconButton className="!mr-1" style={{ backgroundColor: mode === 'one-photo' ? '#1D66CF' : 'white' }} onClick={() => handleModeChange('one-photo')}>
                <CropPortraitIcon
                  className={classnames('text-icons-color', {
                    '!text-white': mode === 'one-photo',
                  })}
                />
              </IconButton>
              <IconButton className="!mr-1 layout-btns" style={{ backgroundColor: mode === 'two-photos' ? '#1D66CF' : 'white' }} onClick={() => handleModeChange('two-photos')}>
                <SplitscreenIcon
                  className={classnames('text-icons-color', {
                    '!text-white': mode === 'two-photos',
                  })}
                />
              </IconButton>
            </div>
          </div>
          <div className="w-full">
            <Camera ref={webcamOneRef} image={photos?.[0]} type={mode === 'two-photos' ? 'ceiling' : 'both'} zoom={zoom} />
          </div>
          {mode === 'two-photos' && (
            <>
              {(photos?.[0] || !tipsWatched) && (
                <div className="h-[100dvh] w-full absolute top-0 z-10 flex justify-center items-center">
                  <div className="flex flex-row justify-center items-center bg-cover h-[54px] w-full" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/overlap-pattern.svg)` }}>
                    <div className="text-black h-[28px] text-center text-lg bg-white/80 overlap px-2">OVERLAP</div>
                  </div>
                </div>
              )}
              <div className="w-full">
                <Camera ref={webcamTwoRef} image={photos?.[1]} type="floor" blank={!photos?.[0]} zoom={zoom} />
              </div>
            </>
          )}

          <Footer>
            <div className="flex flex-row w-full pt-2 justify-center items-center">
              <div className="shutter-btn rounded-full bg-white w-[50px] h-[50px]" onClick={capture} />
            </div>
          </Footer>
        </div>
      )}

      {mode === 'review' && <Review photos={photos} onConfirm={handleConfirm} onRetake={handleRetake} />}

      <Dialog open={alertOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="text-center">
          Change Layout
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
            Are you having trouble fitting everything in this view? Try toggling to the 2 photo layout. You can always toggle between layouts using the buttons in the top left.
          </DialogContentText>
          <Stack direction="column" spacing={2} className="mt-4">
            <Button disableElevation className="!capitalize" variant="contained" startIcon={<SplitscreenIcon />} onClick={handleTwoPhotoCapture}>
              Try 2 photo layout
            </Button>
            <Button className="!capitalize" variant="outlined" startIcon={<CropPortraitIcon />} onClick={() => handleClose(true)}>
              Stay in single photo layout
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default LayoutCapture;
