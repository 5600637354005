import claimId from './common/claimId';
import submitter from './common/submitter';
import { useClaimInfoStore } from 'src/stores/claimInfo';

export type RoomSubmittedImages = {
  total: number;
  images: [
    {
      id: number;
      key: string;
    },
  ];
};

export type RoomSubmittedData = {
  roomId: string;
  damageId: string;
  setName: string;
  totalTimeSeconds: string;
  wallImages: RoomSubmittedImages;
  damageImages: RoomSubmittedImages;
  wallImagesFailed: RoomSubmittedImages;
  damageImagesFailed: RoomSubmittedImages;
};

const roomSubmittedError = (d: any) => {
  const claimInfo = useClaimInfoStore.getState();
  const wallImages = d.uploaded.filter((img: any) => img.imgType === 'overviewImage');
  const damageImages = d.uploaded.filter((img: any) => (img?.imgType || img?.type || '').includes('zoomed'));

  const wallImagesFailed = d.failed.filter((img: any) => img.imgType === 'overviewImage');
  const damageImagesFailed = d.failed.filter((img: any) => (img?.imgType || img?.type || '').includes('zoomed'));

  const data: RoomSubmittedData = {
    ...d,
    wallImages: {
      total: wallImages.length,
      images: wallImages.map((img: any) => ({
        id: img.id + 1,
        key: img.key || img.awsKey,
      })),
    },
    damageImages: {
      total: damageImages.length,
      images: damageImages.map((img: any) => ({
        id: img.id + 1,
        key: img.key || img.awsKey,
      })),
    },
    wallImagesFailed: {
      total: wallImagesFailed.length,
      images: wallImagesFailed.map((img: any) => ({
        id: img.id + 1,
        key: img.imgType,
      })),
    },
    damageImagesFailed: {
      total: damageImagesFailed.length,
      images: damageImagesFailed.map((img: any) => ({
        id: img.id + 1,
        key: img.imgType,
      })),
    },
    damageId: damageImages?.[0]?.damageId || '',
  };

  return [
    submitter(),
    claimId(),
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Room id:*\n<${process.env.REACT_APP_BASE_URL}/#/app/processingInterface/${claimInfo.claimId}/${data.roomId}|${data.roomId}>`,
        },
      ],
    },
    {
      type: 'divider',
    },
    ...(data.wallImages.total
      ? [
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Wall images uploaded: ${data.wallImages.total}*`,
              },
            ],
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: data.wallImages.images.map((img: any) => `*${img.id} -* ${img.key}`).join('\n'),
            },
          },
        ]
      : []),
    ...(data.wallImagesFailed.total
      ? [
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Wall images failed: ${data.wallImagesFailed.total}*`,
              },
            ],
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: data.wallImagesFailed.images.map((img: any) => `*${img.id} -* ${img.key}`).join('\n'),
            },
          },
        ]
      : []),
    ...(data.damageImages.total
      ? [
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Damage images uploaded: ${data.damageImages.total}*`,
              },
            ],
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: data.damageImages.images.map((img: any) => `*${img.id} -* ${img.key}`).join('\n'),
            },
          },
        ]
      : []),
    ...(data.damageImagesFailed.total
      ? [
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Damage images failed: ${data.damageImagesFailed.total}*`,
              },
            ],
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: data.damageImagesFailed.images.map((img: any) => `*${img.id} -* ${img.key}`).join('\n'),
            },
          },
        ]
      : []),
  ];
};

export default roomSubmittedError;
