import claimId from './common/claimId';
import submitter from './common/submitter';

const defaultTemplate = (data: any) => {
  delete data.header;
  return [
    submitter(),
    claimId(),
    ...(data
      ? [
          {
            type: 'divider',
          },
          {
            type: 'context',
            elements: [
              {
                type: 'mrkdwn',
                text: `${JSON.stringify(data)}`,
              },
            ],
          },
        ]
      : []),
  ];
};

export default defaultTemplate;
