import { error, success } from 'src/utils/logger';
import axios from './base';
import { v4 as uuid } from 'uuid';

const isTest = () => {
  return localStorage.getItem('test') === 'true';
};

export const validateLink = (linkId: string) => {
  if (isTest()) {
    return Promise.resolve({
      data: {
        claimId: uuid(),
        entity: 'hostalabs',
        displayName: 'Hosta a.i.',
        linkCreationTime: 1888656745.0,
        submitter: 'test@hostalabs.com',
        logo: null,
        claimInfo: {
          zip: ' ',
          country: 'United States',
          notes: '',
          address2: '',
          city: 'Cambridge',
          claimDate: '',
          address1: '84 Sherman Street',
          customerID: '',
          state: 'MA',
          damageType: '',
        },
        customerId: '',
        allowDamage: false,
      },
    });
  }
  return axios.get(linkId);
};

export const getClaimOverview = (claimId: string) => {
  return axios.get(`/claims/${claimId}`);
};

export const addNewRoom = (claimId: string, roomType: string, roomName: string, roomDesc: string) => {
  console.log(claimId, roomType);
  if (isTest()) return Promise.resolve({ data: { roomId: uuid(), remainingRooms: 10 } });
  return axios.post(`${claimId}`, { roomType, roomName, roomDesc });
};

const uploadImage = (url: string, data: any, abortController?: AbortController) => {
  return axios.post(url, data, {
    headers: {
      authorization: '',
      'content-type': 'multipart/form-data',
    },
    ...(abortController?.signal && { signal: abortController?.signal }),
  });
};

export const putQuestions = (claimId: string, data: any) => {
  return axios.put(`${claimId}/questions`, data);
};

const makeFormData = (uploadFields: any, file: File | Blob) => {
  const data = new FormData();
  data.append('key', uploadFields.key);
  data.append('x-amz-algorithm', uploadFields['x-amz-algorithm']);
  data.append('x-amz-credential', uploadFields['x-amz-credential']);
  data.append('x-amz-date', uploadFields['x-amz-date']);
  data.append('x-amz-security-token', uploadFields['x-amz-security-token']);
  data.append('policy', uploadFields.policy);
  data.append('x-amz-signature', uploadFields['x-amz-signature']);
  data.append('x-amz-meta-claimId', uploadFields.claimId);
  data.append('x-amz-meta-roomId', uploadFields.roomId);
  data.append('x-amz-meta-submitter', uploadFields.submitter);
  data.append('x-amz-meta-imageField', uploadFields.imageField);
  if (uploadFields.imageNumber) data.append('x-amz-meta-imageNumber', uploadFields.imageNumber);
  if (uploadFields.type) data.append('x-amz-meta-type', uploadFields.type);
  if (uploadFields.damageId) data.append('x-amz-meta-damageId', uploadFields.damageId);
  if (uploadFields.imageSetId) data.append('x-amz-meta-imageSetId', uploadFields.imageSetId);
  if (uploadFields.parentImage) data.append('x-amz-meta-parentImage', uploadFields.parentImage);
  data.append('file', file);
  return data;
};

export const stripExcessData = (data: any) => {
  return Object.keys(data).reduce((acc: any, e) => {
    if (!e.toLocaleLowerCase().includes('amz') && !e.toLocaleLowerCase().includes('policy')) acc[e] = data[e];
    return acc;
  }, {});
};

export const getImageLink = (
  claimId: string,
  roomId: string,
  file: File,
  ext: string,
  imageNumber: string,
  imageField = 'overviewImages',
  type?: string,
  parentImage?: any[],
  damageId = {},
  errors: string[] = [],
  abortController?: AbortController,
  recaptureKey?: string | undefined
) => {
  if (isTest()) return Promise.resolve({});
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${claimId}/${roomId}/image`,
        {
          ext,
          imageNumber,
          imageField,
          type,
          parentImage,
          ...damageId,
          recaptureKey: recaptureKey || undefined,
        },
        {
          ...(abortController?.signal && { signal: abortController?.signal }),
        }
      )
      .then((resp) => {
        const formData = makeFormData(resp.data.uploadFields, file);
        if (resp.data.uploadFieldsTxt) {
          const fileData = JSON.stringify(errors);
          const blob = new Blob([fileData], { type: 'text/plain' });
          const textFormData = makeFormData(resp.data.uploadFieldsTxt, blob);
          uploadImage(resp.data.uploadLinkTxt, textFormData, abortController);
        }
        const logData = stripExcessData(resp.data.uploadFields);
        success({ event: 'Pre Image Upload', data: { ...(logData || {}) } });
        uploadImage(resp.data.uploadLink, formData, abortController)
          .then(() => {
            success({ event: 'Image Upload', data: { imageNumber } });
            resolve(resp.data.uploadFields);
          })
          .catch((err) => {
            error({
              event: 'Image Upload Fail',
              data: { imageNumber, error: err },
            });
            reject(err);
          });
      })
      .catch((err) => {
        error({
          event: 'Pre Image Upload Fail',
          data: { imageNumber, error: err },
        });
        reject(err);
      });
  });
};

export const submitRoom = (claimId: string, roomId: string, roomType: string, numPhotos: number, uploadsTracked?: boolean | undefined) => {
  if (isTest()) return Promise.resolve({});
  if (uploadsTracked === true || uploadsTracked === false)
    return axios.post(`${claimId}/${roomId}/process`, {
      roomType,
      numPhotos,
      uploadsTracked: uploadsTracked,
    });
  else return axios.post(`${claimId}/${roomId}/process`, { roomType, numPhotos });
};

export const addContactInfo = (claimId: string, phoneNumberOrEmail: string) => {
  if (isTest()) return Promise.resolve({});
  return axios.post(`${claimId}/addContactInfo`, {
    phoneNumberOrEmail,
  });
};

export const getRecaptureInfo = (linkId: string | undefined, roomId: string | undefined) => {
  if (linkId && roomId) return axios.get(`${linkId}/${roomId}/recapture`);
  return Promise.reject();
};
