import React, { useState } from 'react';
import { dexieDb, handleError } from 'src/utils/indexedDb';
import DamageHeader from 'src/components/Header/DamageHeader';
import ContentView from 'src/components/Content/Content';
import Text from 'src/components/Text/Text';
import numberToOrdinalWord from 'src/utils/numberToOrdinalWord';
import RetakeButton from 'src/components/RetakeButton';
import OnePhotoCapture from './OnePhotoCapture';
import TwoPhotosCapture from './TwoPhotosCapture';

type Props = {
  imageNumber: number;
  onComplete: () => void;
  handleBack: () => void;
  onRetake?: (imageData: string) => void;
};

const RetakePhoto = ({ imageNumber, onComplete, handleBack, onRetake }: Props) => {
  const [mode, setMode] = useState<string>('none');
  const handleComplete = async (imageData: string[]) => {
    if (imageData.length > 1) {
      await dexieDb.pictures
        .update(imageNumber, {
          content: imageData[0],
          extraImages: [imageData[1]],
        })
        .catch(handleError);
    } else if (imageData.length === 1) {
      await dexieDb.pictures.update(imageNumber, { content: imageData[0], extraImages: [] }).catch(handleError);
    }

    if (onRetake && mode === 'one') {
      // AI assisted capture retake with one photo mode
      onRetake(imageData[0]);
    } else {
      // Small room capture only and AI assisted capture two photos mode
      onComplete();
    }
  };

  if (mode === 'one') return <OnePhotoCapture handleComplete={handleComplete} handleCancel={() => setMode('none')} />;
  if (mode === 'two') return <TwoPhotosCapture handleComplete={handleComplete} handleCancel={() => setMode('none')} />;

  return (
    <>
      <DamageHeader handleBack={handleBack} />
      <ContentView>
        <>
          <Text variant="title">How do you want to retake your {numberToOrdinalWord(imageNumber).toLowerCase()} wall?</Text>
          <Text variant="subtitle" extraClassname="pt-8 pb-10">
            If you can’t see every edge in your camera view, take two overlapping photos.
          </Text>

          <RetakeButton title="Retake with 1 Photo" subtitle="I can capture entire wall with one photo" icon="/icons/small-room/one-photo.svg" onClick={() => setMode('one')} />

          <div className="m-2" />

          {imageNumber < 16 && <RetakeButton title="Retake with 2 Photos" subtitle="I need two photos to capture entire wall" icon="/icons/small-room/two-photos.svg" onClick={() => setMode('two')} />}
        </>
      </ContentView>
    </>
  );
};

export default RetakePhoto;
