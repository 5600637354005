import { Dialog, Transition } from '@headlessui/react';
import React, { useState, Fragment } from 'react';

import BaseButton from 'src/components/BaseButton/BaseButton';
import Text from 'src/components/Text/Text';

export interface IModalProps {
  title: string;
  text: string;
  buttonText?: string;
  handleClose?(): void;
  handleDone?(): void;
  doneButtonText?: string;
  noCloseHandlers?: boolean;
}

const Modal = (props: IModalProps) => {
  const { title, text, buttonText, handleClose, handleDone, doneButtonText, noCloseHandlers = false } = props;
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    if (handleClose) handleClose();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative" data-testid="info-modal" onClose={() => (!noCloseHandlers ? closeModal() : undefined)}>
        <div id="info-modal" className="fixed inset-0 overflow-y-auto bg-black/30 z-[100]">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-white">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full border border-[#043C8B] bg-[#F5F9FF] max-w-[400px] py-3 px-4 transform overflow-hidden rounded-lg text-white text-left align-middle shadow-xl transition-all z-10">
                <Dialog.Title as="h3" className="text-lg font-semibold text-red-500" data-testid="info-modal-title">
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <Text align="left" variant="paragraph" extraClassname="text-gray-500" testId="info-modal-text">
                    {text}
                  </Text>
                </div>

                {doneButtonText && (
                  <div className="flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop">
                    <BaseButton dataTestId="info-modal-done-btn" label={doneButtonText} className="w-full m-1 bg-red-500 desktops:w-[70%] mx-auto}" onClick={handleDone} />
                  </div>
                )}
                {buttonText && (
                  <div className="flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop">
                    <BaseButton dataTestId="info-modal-close-btn" label={buttonText} className="w-full mt-8 m-1 desktops:w-[70%] mx-auto}" onClick={closeModal} />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
