import BaseButton from 'src/components/BaseButton/BaseButton';
import Footer from 'src/components/Footer/Footer';

type Props = {
  label: string;
  eventName: string;
  onCapture: (data: string, file?: File) => void;
  capFirstLetterOnly?: boolean;
};

const TakePhotoFooterButton = ({ label, eventName, onCapture, capFirstLetterOnly }: Props) => (
  <Footer>
    <BaseButton
      dataTestId="take-photo-footer-btn"
      className="bg-[#1D66CF]"
      type="capture"
      icon="/icons/camera@2x.png"
      name={eventName}
      onCapture={onCapture}
      label={label}
      capFirstLetterOnly={capFirstLetterOnly}
    />
  </Footer>
);

export default TakePhotoFooterButton;
