import { create } from 'zustand';

import AIModel from 'src/utils/model';

interface ImageStore {
  image: string | null;
  errors: string[];
  success: boolean;
  modelLoaded: boolean;
  model: AIModel;
  setErrors: (errors: string[]) => void;
  setImage: (image: string | null) => void;
  setSuccess: (val: boolean) => void;
  setModelLoaded: (val: boolean) => void;
  setAiModel: (val: AIModel) => void;
}

export const useImageStore = create<ImageStore>((set) => ({
  image: null,
  modelLoaded: false,
  errors: [],
  success: false,
  model: new AIModel(),
  setErrors: (errors: string[]) => set((state: ImageStore) => ({ errors: errors })),
  setImage: (image: string | null) => set(() => ({ errors: [], image: image })),
  setSuccess: (value: boolean) => set((state: ImageStore) => ({ ...state, success: value })),
  setModelLoaded: (value: boolean) => set((state: ImageStore) => ({ ...state, modelLoaded: value })),
  setAiModel: (value: AIModel) => set((state: ImageStore) => ({ ...state, model: value })),
}));
