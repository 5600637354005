import classNames from 'classnames';
import { Fragment, type PropsWithChildren } from 'react';

type Props = {
  align?: 'center' | 'left' | 'right';
  variant: 'subtitle' | 'title' | 'paragraph';
  weight?: 'bold' | 'semibold' | 'light' | 'normal' | 'medium';
  testId?: string;
  extraClassname?: string;
  capFirstLetterOnly?: boolean;
};

const addLineBreak = (str: string) =>
  str.split('\n').map((subStr, idx) => {
    return (
      <Fragment key={idx}>
        {subStr}
        <br />
      </Fragment>
    );
  });

const Text = ({ align = 'center', weight = 'normal', variant, children, testId = 'text-test-id', extraClassname, capFirstLetterOnly }: PropsWithChildren<Props>) => {
  return (
    <p
      className={classNames(
        `text-${align}`,
        {
          'text-2xl text-title-color font-semibold': variant === 'title',
          'text-lg text-subtitle-color leading-tight': variant === 'subtitle',
          'text-l text-subtitle-color leading-tight': variant === 'subtitle',
          'font-normal': weight === 'normal' && variant !== 'title',
          'font-semibold': weight === 'semibold',
          'font-light': weight === 'light',
          'font-bold': weight === 'bold',
          'font-medium': weight === 'medium',
          capitalize: capFirstLetterOnly === false && variant === 'title',
          'first-letter:capitalize': capFirstLetterOnly === true && variant === 'title',
        },
        `${extraClassname || ''}`
      )}
      data-testid={testId}>
      {typeof children === 'string' ? addLineBreak(children as string) : children}
    </p>
  );
};

export default Text;
