export interface Theme {
  [key: string]: string;
}

export interface MappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: Theme): MappedTheme => {
  const fontFamilies = variables?.['fontFamilies'];
  return {
    // Theming
    '--icons-color': variables?.['icons-color'] || '',
    '--title-color': variables?.['title-color'] || '',
    '--subtitle-color': variables?.['subtitle-color'] || '',
    '--base-btn-color-primary': variables?.['base-btn-color-primary'] || '',
    '--base-btn-color-secondary': variables?.['base-btn-color-secondary'] || '',
    '--base-btn-color-disabled': variables?.['base-btn-color-disabled'] || '',
    '--screen-bg-primary': variables?.['screen-bg-primary'] || '',
    '--screen-bg-secondary': variables?.['screen-bg-secondary'] || '',
    ...(fontFamilies && { '--font-families': `"${fontFamilies}"` }),
    // Plyr theming https://github.com/sampotts/plyr?tab=readme-ov-file#customizing-the-css
    '--plyr-color-main': variables?.['base-btn-color-secondary'] || '',
  };
};

export const applyTheme = (theme: Theme): void => {
  const themeObject = mapTheme(theme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};
