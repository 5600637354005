import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseXIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagePreview = ({ url, label }: { url: string; label: string }) => {
  const [expand, setExpand] = useState(false);

  const handleClose = () => setExpand(false);

  return (
    <div className="my-2">
      <Dialog fullScreen open={expand} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar color="default" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <CloseXIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {label}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="w-full h-full flex justify-center items-center">
          <img src={url} alt="Full" />
        </div>
      </Dialog>
      <div className="border w-24 p-2 rounded-lg">
        <small className="text-[10px]">{label}</small>
        <img src={url} onClick={() => setExpand(true)} className="h-auto" alt="Left" />
      </div>
    </div>
  );
};

export default ImagePreview;
