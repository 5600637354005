import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/ChevronLeft';
import Text from 'src/components/Text/Text';

const AppHeader = ({ label, onClose, hideBack = false }: { onClose: () => void; label: string; hideBack?: boolean }) => {
  const className = hideBack ? 'flex-1' : 'flex-1 mr-[40px]';

  return (
    <div className="flex items-center justify-center p-2 max-w-[500px] mx-auto w-full">
      {!hideBack && (
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" className="flex-none">
          <CloseIcon />
        </IconButton>
      )}
      <Text extraClassname={className} variant="subtitle">
        {label}
      </Text>
    </div>
  );
};

export default AppHeader;
