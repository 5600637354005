import CircularProgress from '@mui/material/CircularProgress';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { dexieDb } from 'src/utils/indexedDb';

import CaptureImage from 'src/pages/Damage/RoomCapture/components/CaptureImage/CaptureImage';

import * as log from 'src/utils/logger';

const getOverViewImages = async (roomId?: string) =>
  dexieDb.pictures
    .where({ roomId: roomId })
    .filter((e) => e.imgType === 'overviewImage')
    .toArray();

const RoomCapture = () => {
  const [step, setStep] = useState(1);
  const { roomId } = useParams();
  const navigate = useNavigate();
  const photos = useLiveQuery(async () => getOverViewImages(roomId), [roomId]);

  useEffect(() => {
    (async () => {
      const overViewImages = await getOverViewImages(roomId);
      overViewImages?.length && setStep(overViewImages.length + 1);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    log.event({ event: 'Finished Capture', data: photos });
    navigate('../confirm-photos');
  };

  const handleNext = () => {
    setStep((prevState) => prevState + 1);
  };

  const totalImages =
    photos?.reduce((acc, curr) => {
      return acc + 1 + (curr.extraImages?.length || 0);
    }, 0) || 0;

  if (photos === undefined) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <CircularProgress />
        <p>Loading Photos</p>
      </div>
    );
  }

  return <CaptureImage imageNumber={step} handleNext={handleNext} totalPics={totalImages} handleFinish={onFinish} />;
};

export default RoomCapture;
