import { create } from 'zustand';

type GenericObject = {
  [key: string]: any;
};

export type State = {
  copy: {
    [key: string]: GenericObject;
  };
  config: GenericObject;
};

export type EntityConfigStore = State & {
  setEntityData: (data: State) => void;
};

export const useEntityConfigStore = create<EntityConfigStore>((set) => ({
  copy: {},
  config: {},
  setEntityData: (data: State) => set((state) => ({ ...state, ...data })),
}));
